import { IPostcodeInfo, PostcodeService } from '../../../../services/postcode.service';
import { CommonModule } from '@angular/common';
import { Component, ViewChild, type OnInit, Input } from '@angular/core';
import { User } from 'src/app/services/user';
import DataSource from 'devextreme/data/data_source';
import { DxFormComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { PrioritizationService, IPrioritizationInfo } from 'src/app/services/prioritization.service';
import { IChoiceInfo } from 'src/app/services/choice.service';
import { finalize } from 'rxjs/operators';
import { showDialog } from 'src/app/common/dialog/dialog';

import { ScoreManagementComponent } from '../score-management.component';

@Component({
    selector: 'app-score-edit',
    templateUrl: './score-edit.component.html',
    styleUrls: ['./score-edit.component.scss'],
})
export class ScoreEditComponent implements OnInit {
    @ViewChild(DxFormComponent, { static: true }) form: DxFormComponent;
    @ViewChild('select') select: DxSelectBoxComponent;
    @Input() formChoice: IChoiceInfo;
    public mainForm: ScoreManagementComponent;

    name = "";

    constructor(
        private serviceScore: PrioritizationService) {
        // this.formChoice = {} as any;
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnInit() {
        console.log("this.mainForm.choice:",this.mainForm)
    }
    onEdit(data){
        console.log("data:",data)
        console.log("this.mainForm.choice:",this.mainForm)
        this.formChoice = data;
    }

    public clearForm() {
        this.form.instance.resetValues();
    }
    Save() {
        // this.clearForm();
        console.log("this.mainForm.choice:",this.mainForm.choice)
        const index = this.mainForm.choice.findIndex(e=>e.PatternCriteriaChoiceKeyId.CHOICE_ID === this.formChoice.PatternCriteriaChoiceKeyId.CHOICE_ID);
        this.mainForm.choice[index] = this.formChoice;

        this.mainForm.multiView.selectedIndex = 0;
    }

    Back() {
        // this.clearForm();
        this.mainForm.multiView.selectedIndex = 0;
    }
}
