<div class="container-fluid">
    <div class="card">
        <dx-multi-view [swipeEnabled]="false" [selectedIndex]="_index">
            <dxi-item>
                <label for="" class="my-2">ชื่อรูปแบบการประเมินคัดเลือก:</label>
                <dx-text-box [(value)]="PATTERN_NAME" [readOnly]="true" [width]=""></dx-text-box>
                <label for="" class="my-2">ลำดับ:</label>
                <dx-text-box [(value)]="CRITERIA_ID" [readOnly]="true" [width]="70"></dx-text-box>
                <label for="" class="my-2">หัวข้อเรื่อง:</label>
                <dx-text-box [(value)]="CRITERIA_NAME" [readOnly]="true" [width]=""></dx-text-box>
                <div class="d-flex">
                    <div class="inputgroup">
                        <label for="" class="mt-4 mb-2">จำนวนข้อระดับความเสี่ยง:</label>
                    </div>
                    <div class="d-flex w-100 justify-content-end">
                        <label for="" class="mt-4 mb-2">ตัวช่วยตั้งต้นระดับความเสี่ยง:</label>
                        <dx-button class="btn-add-datagrid copy" text="ทำการคัดลอก" (onClick)="GenRisk()"></dx-button>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="inputgroup">
                        <dx-text-box [(value)]="CHOICE_QTY" [width]="70"></dx-text-box>
                        <dx-button class="btn-add-datagrid" text="ประมวลจำนวนข้อลำดับความเสี่ยง" (onClick)="GenRisk()"></dx-button>
                    </div>
                    <dx-select-box #select [dataSource]="dataSourceGroup" displayExpr="label" [(value)]="SELECT"
                            valueExpr="value" placeholder="ตัวช่วยตั้งต้นระดับความเสี่ยง"
                            (onValueChanged)="OnGroupSelected($event)" [width]="400">
                        </dx-select-box>
                </div>
                <div class="card-body ">
                    <div class="panel panel-default">
                        <br>
                        <div class="d-flex justify-content-center">
                            <dx-data-grid #grid [dataSource]="choice" [columnHidingEnabled]="true" [showColumnLines]="true"
                                noDataText="ไม่มีข้อมูล" [showRowLines]="true" [showBorders]="true"
                                [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
                                [selectedRowKeys]="[]" [width]="630"  keyExpr="PatternCriteriaChoiceKeyId.CHOICE_ID">
                            <dxo-scrolling mode="standard"></dxo-scrolling>
                            <dxi-column dataField="" caption="ลำดับ" [width]="80" alignment="center" cellTemplate="cellTemplate">
                                <div *dxTemplate="let cell of 'cellTemplate'">
                                    {{ cell.component.pageIndex() * cell.component.pageSize() + cell.rowIndex + 1 }}
                                </div>
                            </dxi-column>
                            <dxi-column dataField="CHOICE_NAME" caption="ระดับความเสี่ยง" [width]="150" alignment="center"></dxi-column>
                            <dxi-column dataField="SCORE_VALUE" caption="ค่าคะแนน" [width]="150" alignment="center"></dxi-column>
                            <dxi-column dataField="CHOICE_DESC" caption="คำอธิบาย" [width]="150" alignment="center"></dxi-column>
                            <dxi-column dataField="" caption=" " [width]="80" alignment="center" [allowSorting]="false" cellTemplate="cellTemplateCommandEdit"></dxi-column>
                            <div *dxTemplate="let d of 'cellTemplateCommandEdit'">
                                <dx-button icon="edit" requirePermission="EDIT" hint="แก้ไข" type="default" (onClick)="Edit(d)"></dx-button>
                            </div>

                            <div *dxTemplate="let cell of 'cellTemplate'">
                                {{ cell.component.pageIndex() * cell.component.pageSize() + cell.rowIndex + 1 }}
                            </div>
                        </dx-data-grid>
                        </div>
                    </div>
                </div>
                <div class="panel panel-default mt-3">
                    <div class="panel-body d-flex">
                        <div class="col-md-6">
                            <dx-button icon="revert" class="back-button btn-size" hint="ย้อนกลับ" text="ย้อนกลับ"
                                (onClick)="Back()">
                            </dx-button>
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex justify-content-end">
                                <dx-button icon="close" class="back-button btn-size" hint="ยกเลิก" text="ยกเลิก" (onClick)="Back()"> </dx-button>
                                <dx-button-improve id="dx-button-improv" class="save-button btn-size" icon="floppy" text="บันทึก" (clicked)="Save($event)"></dx-button-improve>
                            </div>
                        </div>
                    </div>
                </div>
            </dxi-item>
            <dxi-item>
                <dx-form [formData]="formChoice" labelLocation="top" [colCount]="1">
                    <!-- ลำดับ -->
                    <dxi-item dataField="PatternCriteriaChoiceKeyId.CHOICE_ID" editorType="dxTextBox" [editorOptions]="{ placeholder: 'ลำดับ', showClearButton: true, width: '100px', readOnly: true}">
                        <dxo-label text="ลำดับ"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="" editorType=""></dxi-item>
                    <!-- ระดับความเสี่ยง -->
                    <dxi-item dataField="CHOICE_NAME" editorType="dxTextBox" [editorOptions]="{ placeholder: 'ระดับความเสี่ยง', showClearButton: true }">
                        <dxo-label text="ระดับความเสี่ยง"/>
                        <dxi-validation-rule type="required" message="กรุณากรอกข้อมูล"/>
                    </dxi-item>
                    <!-- คำอธิบาย -->
                    <dxi-item dataField="CHOICE_DESC" editorType="dxTextArea" [editorOptions]="{ placeholder: 'คำอธิบาย', showClearButton: true }">
                        <dxo-label text="คำอธิบาย"></dxo-label>
                    </dxi-item>
                    <!-- ค่าคะแนน -->
                    <dxi-item dataField="SCORE_VALUE" editorType="dxTextBox" [editorOptions]="{ placeholder: 'ค่าคะแนน', showClearButton: true, width: '100px'}">
                        <dxo-label text="ค่าคะแนน"></dxo-label>
                        <dxi-validation-rule type="required" message="กรุณากรอกข้อมูล"/>
                    </dxi-item>
                </dx-form>
                <!-- eslint-disable @angular-eslint/template/alt-text -->
                <div class="panel-body">
                    <div class="col-md-12 text-end">
                        <dx-button icon="revert" class="back-button btn-size" hint="ย้อนกลับ" text="ย้อนกลับ" (onClick)="Back()" ></dx-button>
                        <dx-button-improve id="dx-button-improv" class="save-button btn-size" icon="floppy" text="บันทึก" (clicked)="SaveEdit()" [useSubmitBehavior]="true"></dx-button-improve>
                    </div>
                </div>
            </dxi-item>
        </dx-multi-view>



    </div>
</div>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="_isLoading" [showIndicator]="true" [showPane]="true"
    [shading]="true" [closeOnOutsideClick]="false" message="กำลังโหลดข้อมูล..." shadingColor="rgba(0, 0, 0, 0.2)">
</dx-load-panel>
