<div class="container-fluid">
    <div class="card">
        <dx-multi-view [swipeEnabled]="false" [selectedIndex]="0">
            <dxi-item>
                <div class="d-flex">
                    <div class="col-12 col-md-3">
                        <div class="inputgroup">
                            <dx-text-box placeholder="ค้นหา" [width]="300" style=" border-top-right-radius: 0; border-bottom-right-radius: 0; border-color: #8d2621;"></dx-text-box>
                            <dx-button icon="search" class="button-inputgroup" (onClick)="search()"></dx-button>
                        </div>
                    </div>
                    <div class="ms-auto">
                        <dx-button icon="add" class="btn-add-datagrid" text="เพิ่ม" (onClick)="Add(null)"></dx-button>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="panel panel-default">
                        <br>
                        <dx-tree-list id="tasks" [dataSource]="organize" keyExpr="ORGANIZE_ID"
                            parentIdExpr="ORGANIZE_LEVEL_PARENT" [columnHidingEnabled]="true" [showColumnLines]="true"
                            noDataText="ไม่มีข้อมูล" [showRowLines]="true" [showBorders]="true"
                            [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
                            [selectedRowKeys]="[]">
                            <dxo-scrolling mode="standard"></dxo-scrolling>
                            <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50]" [showInfo]="true"></dxo-pager>
                            <dxi-column dataField="" caption="ลำดับ" [width]="50" alignment="center"></dxi-column>
                            <dxi-column dataField="pattern_name" caption="หัวข้อ ITGC ( ภาษาไทย )" alignment="center"></dxi-column>
                            <dxi-column dataField="" caption="หัวข้อ ITGC ( ภาษาอังกฤษ )" alignment="center"></dxi-column>
                            <dxi-column dataField="create_date" caption="สร้างโดย" [width]="130" alignment="center" [calculateDisplayValue]="GetStatus"></dxi-column>
                            <dxi-column dataField="create_date" caption="วันที่สร้าง" [width]="130" alignment="center" [calculateDisplayValue]="GetStatus"></dxi-column>
                            <dxi-column dataField="" caption="แก้ไขโดย" [width]="150" alignment="center" [calculateDisplayValue]="GetStatus"></dxi-column>
                            <dxi-column dataField="update_date" caption="วันที่แก้ไข" [width]="130" alignment="center" [calculateDisplayValue]="GetStatus"></dxi-column>
                            <dxi-column dataField="" caption=" " [width]="80" alignment="center" [allowSorting]="false" cellTemplate="cellTemplateCommandEdit"></dxi-column>
                            <div *dxTemplate="let d of 'cellTemplateCommandEdit'">
                                <dx-button icon="edit" requirePermission="EDIT" hint="แก้ไข" type="default" (onClick)="Edit(d)"></dx-button>
                                <dx-button icon="eye" requirePermission="VIEW" hint="รายละเอียด" type="default" (onClick)="View(d)"></dx-button>
                            </div>
                            <div *dxTemplate="let cell of 'cellTemplate'">
                                {{ cell.component.pageIndex() * cell.component.pageSize() + cell.rowIndex + 1 }}
                            </div>
                        </dx-tree-list>
                    </div>
                </div>
            </dxi-item>
            <dxi-item>
                <app-iis-edit [formData]="formOrganize"></app-iis-edit>
            </dxi-item>
        </dx-multi-view>

    </div>
</div>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="_isLoading" [showIndicator]="true" [showPane]="true"
    [shading]="true" [closeOnOutsideClick]="false" message="กำลังโหลดข้อมูล..." shadingColor="rgba(0, 0, 0, 0.2)">
</dx-load-panel>
