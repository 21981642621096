<div class="container-fluid">
    <div class="card">
        <dx-multi-view [swipeEnabled]="false" [selectedIndex]="_index">
            <dxi-item>
                <div class="card-body ">
                    <div class="panel panel-default">
                        <label for="" class="my-2">{{PATTERN_NAME}}</label>
                        <dx-tree-list id="groupData" [dataSource]="groupData" [columnHidingEnabled]="true" [showColumnLines]="true"
                            noDataText="ไม่มีข้อมูล" [showRowLines]="true" [showBorders]="true"
                            [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
                            keyExpr="PatternCriteriaKeyId.CRITERIA_ID"
                            >
                            <dxo-scrolling mode="standard"></dxo-scrolling>
                            <dxi-column dataField="" caption="ลำดับ" [width]="80" alignment="center" [allowEditing]="false" cellTemplate="cellTemplate">
                                <div *dxTemplate="let cell of 'cellTemplate'">
                                    {{ cell.component.pageIndex() * cell.component.pageSize() + cell.rowIndex + 1 }}
                                </div>
                            </dxi-column>
                            <dxi-column caption="หัวข้อเรื่อง" dataField="CRITERIA_NAME" [width]="700" alignment="left"[allowSorting]="false" cellTemplate="callCRITERIA_NAME"/>
                            <div *dxTemplate="let d of 'callCRITERIA_NAME'">
                                <!-- มี icon i ต่อท้าย กดคลิกแสดง popup เป็นรายละเอียดหัวข้อนั้น -->
                                {{d.data.CRITERIA_NAME}}
                                <!-- เงื่อนไข OPINION_FLAG = Y ถึงแสดง textarea -->
                                <textarea *ngIf="OPINION_FLAG === 'Y'" class="form-control my-3" style="width: 100%; height: 150px;" (ngModel)="d.data.CRITERIA_NAME" (change)="onTextareaChange($event)"></textarea>
                                <ng-container *ngFor="let item of d.data.choice;">
                                    <div class="w-100 d-flex px-4 mb-2">
                                        <input class="me-3" type="radio" name="1" (ngChecked)="d.data.SELECT_CHOICE" (change)="onRadioButtonChange(item.CHOICE_NAME)"/>
                                        <span style="width:100%">{{ item.CHOICE_NAME }}</span>
                                        <span style="width:100px">= {{ item.SCORE_VALUE }} คะแนน</span>
                                    </div>
                                </ng-container>
                            </div>
                            <dxi-column dataField="WEIGHT" caption="น้ำหนัก" [width]="130" alignment="center"/>
                            <dxi-column dataField="" caption="คะแนนถ่วงน้ำหนัก (น้ำหนัก x คะเเนน / 100)" alignment="center"/>

                        </dx-tree-list>
                    </div>
                </div>
            </dxi-item>
            <dxi-item>
                <app-prioritization-edit [formData]="formPrioritization"></app-prioritization-edit>
            </dxi-item>
        </dx-multi-view>

    </div>
</div>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="_isLoading" [showIndicator]="true" [showPane]="true"
    [shading]="true" [closeOnOutsideClick]="false" message="กำลังโหลดข้อมูล..." shadingColor="rgba(0, 0, 0, 0.2)">
</dx-load-panel>
