import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { req } from '../common/betimes-http-request';
import { trimObject } from '../common/helper';
import { IPagingResult, OffsetFilterParam } from '../common/common-type';
import DataSource from 'devextreme/data/data_source';

@Injectable({
    providedIn: 'root'
})
export class SubtopicService {

    [x: string]: any;

    constructor() { }

    public GetSubtopic(filter?: SubtopicFilterParam): Observable<IPagingResult<ISubtopicInfo>> {
        trimObject(filter);
        return req<IPagingResult<ISubtopicInfo>>('/mossubtopic')
            .queryString( filter as any)
            .get();
    }

    public GetSubtopicByID(id: number): Observable<ISubtopicInfo> {
        return req<ISubtopicInfo>('/mossubtopic/' + id).get();
    }

    public PostSubtopic(param: any): Observable<ISubtopicInfo> {
        return req<ISubtopicInfo>('/mossubtopic').body(param).post();
    }

    public PutSubtopic(id: number, param: ISubtopicInfo): Observable<ISubtopicInfo> {
        return req<ISubtopicInfo>('/mossubtopic/' + id).body(param).put();
    }
}

export interface ISubtopicInfo {
    SUBTOPIC_ID?: number;
    SUBJECT_ID?: number;
    PATTERN_ID?: number;
    CRITERIA_ID?: number;
    WEIGHT?: number;
    WEIGHT_SCORE?: any;
}


export interface SubtopicFilterParam extends OffsetFilterParam {
    subjectId?: number;
}
