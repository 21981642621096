<div class="container-fluid">
    <div class="card">
        <dx-multi-view [swipeEnabled]="false" [selectedIndex]="_index">
            <dxi-item>
                <div class="card-body ">
                    <div class="row">
                        <div class="col-2 mb-2">
                            <label for="" class="mb-1">ปีงบประมาณ :</label>
                            <dx-text-box class="" [readOnly]="true" [(value)]="BUDGET_YEAR"></dx-text-box>
                        </div>
                        <div class="col-10 mb-2">
                            <label for="" class="mb-1">ระบบบริหารงานตรวจสอบ :</label>
                            <dx-text-box class="" [readOnly]="true" [(value)]="APP_INFO_NAME"></dx-text-box>
                        </div>
                        <div class="col-12 mb-2">
                            <label for="" class="mb-1">ชื่อเรื่อง :</label>
                            <dx-text-box class="" [readOnly]="true" [(value)]="SUBJECT_INFO"></dx-text-box>
                        </div>
                        <div class="col-12 mb-2">
                            <label for="" class="mb-1">ที่มา :</label>
                            <dx-text-box class="" [readOnly]="true" [(value)]="PATTERN_NAME"></dx-text-box>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <label for="" class="my-2">{{PATTERN_NAME}}</label>
                        <dx-tree-list id="groupData" [dataSource]="groupData" [columnHidingEnabled]="true" [showColumnLines]="true"
                            noDataText="ไม่มีข้อมูล" [showRowLines]="true" [showBorders]="true"
                            [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
                            keyExpr="PatternCriteriaKeyId.CRITERIA_ID"
                            >
                            <dxo-scrolling mode="standard"></dxo-scrolling>
                            <dxi-column dataField="" caption="ลำดับ" [width]="80" alignment="center" [allowEditing]="false" cellTemplate="cellTemplate">
                                <div *dxTemplate="let cell of 'cellTemplate'">
                                    {{ cell.component.pageIndex() * cell.component.pageSize() + cell.rowIndex + 1 }}
                                </div>
                            </dxi-column>
                            <dxi-column caption="หัวข้อเรื่อง" dataField="CRITERIA_NAME" [width]="650" alignment="left" [allowSorting]="false" cellTemplate="callCRITERIA_NAME"/>
                            <div *dxTemplate="let d of 'callCRITERIA_NAME'">
                                <!-- มี icon i ต่อท้าย กดคลิกแสดง popup เป็นรายละเอียดหัวข้อนั้น -->
                                {{ d.data.CRITERIA_NAME }}
                                <!-- เงื่อนไข OPINION_FLAG = Y ถึงแสดง textarea -->
                                <textarea *ngIf="OPINION_FLAG === 'Y'" class="form-control my-3" style="width: 100%; height: 150px;" [value]="d.data.ANS_DESC" (change)="onTextareaChange(d.data,$event)"></textarea>
                                <ng-container *ngFor="let item of d.data.choice;">
                                    <div class="w-100 d-flex px-4 mb-2">
                                        <input class="me-3" type="radio" name="{{d.data.PatternCriteriaKeyId.CRITERIA_ID}}" [value]="item.SELECT_CHOICE" [checked]="item.SELECT_CHOICE" (change)="onRadioButtonChange(item,$event)"/>
                                        <span style="width:100%">{{ item.CHOICE_NAME }}</span>
                                        <span style="width:100px">= {{ item.SCORE_VALUE }} คะแนน</span>
                                    </div>
                                </ng-container>
                            </div>
                            <dxi-column dataField="WEIGHT" caption="น้ำหนัก" [width]="130" alignment="center"/>
                            <dxi-column dataField="WEIGHT_SCORE" caption="คะแนนถ่วงน้ำหนัก (น้ำหนัก x คะเเนน / 100)" alignment="center"/>

                        </dx-tree-list>
                    </div>
                </div>
            </dxi-item>
            <dxi-item>
                <app-prioritization-edit [formData]="formPrioritization"></app-prioritization-edit>
            </dxi-item>
        </dx-multi-view>

        <div class="row">
            <div class="col-md-6 text-start mt-4">
                <dx-button icon="revert" class="back-button btn-size" hint="ย้อนกลับ" text="ย้อนกลับ" (onClick)="Back()" ></dx-button>
            </div>
            <div *ngIf="isData" class="col-md-6 text-end mt-4">
                <dx-button-improve id="dx-button-improv" class="save-button btn-size" icon="" text="บันทึกร่าง" (clicked)="Save($event)" [useSubmitBehavior]="true"></dx-button-improve>
                <dx-button-improve id="dx-button-improv" class="save-button btn-size" icon="floppy" text="บันทึก" (clicked)="Save($event)" [useSubmitBehavior]="true" style="margin-right: 0px !important;"></dx-button-improve>
            </div>
        </div>

    </div>
</div>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="_isLoading" [showIndicator]="true" [showPane]="true"
    [shading]="true" [closeOnOutsideClick]="false" message="กำลังโหลดข้อมูล..." shadingColor="rgba(0, 0, 0, 0.2)">
</dx-load-panel>
