import { Component, ViewChild, type OnInit, Input } from '@angular/core';
import { DxMultiViewComponent } from 'devextreme-angular';
import { Router,ActivatedRoute, NavigationExtras } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { DxFormComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { PrioritizationService, IPrioritizationInfo } from 'src/app/services/prioritization.service';
import { GroupService, IGroupInfo, GroupFilterParam } from 'src/app/services/group.service';
import { User } from 'src/app/services/user';
import { showConfirmDialog, showDialog } from 'src/app/common/dialog/dialog';
import { finalize } from 'rxjs/operators';
import { ChoiceService } from 'src/app/services/choice.service';
import { SubjectRegistrationComponent } from '../subject-registration.component';
import { SubjectService, ISubjectInfo, SubjectFilterParam } from 'src/app/services/subject.service';
import { SubtopicService, ISubtopicInfo, SubtopicFilterParam } from 'src/app/services/subtopic.service';

@Component({
    selector: 'app-subject-registration-view',
    templateUrl: './subject-registration-view.component.html',
    styleUrls: ['./subject-registration-view.component.scss'],
})
export class SubjectRegistrationViewComponent implements OnInit {

    @ViewChild(DxFormComponent, { static: true }) form: DxFormComponent;
    @ViewChild(DxMultiViewComponent, { static: true }) multiView: DxMultiViewComponent;
    @Input() formAssessment: IGroupInfo;
    public mainForm: SubjectRegistrationComponent;

    prioritization: IPrioritizationInfo[];
    _filter: GroupFilterParam = {};
    _isLoading = false;
    _index = 0;
    groupData = [];
    subTopicData = [];
    CRITERIA_QTY = "";
    PATTERN_ID = 0;
    PATTERN_NAME = "";
    formGroup: IGroupInfo;
    formSubTopic: ISubtopicInfo;
    cellData = "";
    OPINION_FLAG = "";
    RATING_FLAG = "";
    WEIGHT_FLAG = "";
    BUDGET_YEAR = "";
    SUBJECT_ID = 0;
    SUBJECT_INFO = "";
    APP_INFO_NAME = "";
    SUM_SCORE = 0;
    SUM_WEIGHT = 0;
    isData = false;
    radioSelect: string;
    selectedOption: any;

    public formPrioritization: IGroupInfo;

    constructor(
        private servicePrioritization: PrioritizationService,
        private serviceChoice: ChoiceService,
        private serviceGroup: GroupService,
        private serviceSubject: SubjectService,
        private serviceSubtopic: SubtopicService,
        private router: Router,
        private route: ActivatedRoute) {
        this.formPrioritization = {} as any;
    }

    ngOnInit() {
        // this.form.mainForm = this;
        this.route.queryParamMap.subscribe(async params => {
            const patternId = params.get('PATTERN_ID');
            const data = await this.servicePrioritization
                .GetPrioritization({
                    requireTotalCount: true,
                    patternid: Number(patternId),
                    recordStatus: false
                })
                .toPromise()
                .then((data: any) => ({
                    data: data.Data,
                    totalCount: data.TotalCount,
                }))
                .catch(error => {
                    console.log(error)
                    this._isLoading = false;
                    throw new Error('Data Loading Error');
                });

            this.PATTERN_ID = Number(patternId);
            this.PATTERN_NAME = params.get('PATTERN_NAME');
            this.OPINION_FLAG = params.get('OPINION_FLAG');
            this.RATING_FLAG = params.get('RATING_FLAG');
            this.WEIGHT_FLAG = params.get('WEIGHT_FLAG');
            this.BUDGET_YEAR = params.get('BUDGET_YEAR');
            this.SUBJECT_ID = Number(params.get('SUBJECT_ID'));
            this.SUBJECT_INFO = params.get('SUBJECT_INFO');
            this.APP_INFO_NAME = params.get('APP_INFO_NAME');

            const groupChioce = await this.serviceChoice
                .GetChoicePattern(this.PATTERN_ID)
                .toPromise()
                .then((data: any) => ({
                    data: data
                }))
                .catch(error => {
                    console.log(error)
                    this._isLoading = false;
                    throw new Error('Data Loading Error');
                });

            for(const item of data.data) {
                const array = groupChioce.data.filter(e => e.PatternCriteriaChoiceKeyId.CRITERIA_ID == item.PatternCriteriaKeyId.CRITERIA_ID && e.RECORD_STATUS == 'A')
                item.choice = array
            }

            this.groupData = data.data;
            this.groupData = this.groupData.map(item => { return { ...item, ANS_DESC:""} })
            // console.log('groupData : ',this.groupData)

            if (this.SUBJECT_ID) {
                const dataSubtopic = await this.serviceSubtopic
                    .GetSubtopic({
                        requireTotalCount: true,
                        subjectId: this.SUBJECT_ID,
                        recordStatus: false
                    })
                    .toPromise()
                    .then((data: any) => ({
                        data: data.Data,
                    }))
                    .catch(error => {
                        console.log(error)
                        this._isLoading = false;
                        throw new Error('Data Loading Error');
                    });

                for(const i of this.groupData) {
                    if (dataSubtopic.data.length != 0) {
                        const dataSub = dataSubtopic.data.filter(e => e.CRITERIA_ID == i.PatternCriteriaKeyId.CRITERIA_ID)
                        if (dataSub.length != 0) {
                            i.WEIGHT_SCORE = dataSub[0].WEIGHT_SCORE
                            this.subTopicData.push({
                                SUBTOPIC_ID: dataSub[0].SUBTOPIC_ID,
                                SUBJECT_ID: dataSub[0].SUBJECT_ID,
                                PATTERN_ID: dataSub[0].PATTERN_ID,
                                CRITERIA_ID: dataSub[0].CRITERIA_ID,
                                CHOICE_ID: dataSub[0].CHOICE_ID,
                                WEIGHT: dataSub[0].WEIGHT,
                                WEIGHT_SCORE: dataSub[0].WEIGHT_SCORE,
                            })
                            for(const c of i.choice) {
                                if (c.PatternCriteriaChoiceKeyId.CHOICE_ID == dataSub[0].CHOICE_ID) {
                                    c.SELECT_CHOICE = "checked"
                                }
                            }
                        } else {
                            this.isData = true
                            this.subTopicData.push({
                                SUBTOPIC_ID: 0,
                                SUBJECT_ID: this.SUBJECT_ID,
                                PATTERN_ID: this.PATTERN_ID,
                                CRITERIA_ID: i.PatternCriteriaKeyId.CRITERIA_ID,
                                CHOICE_ID: 0,
                                WEIGHT: i.WEIGHT,
                                WEIGHT_SCORE: 0,
                            })
                        }
                    } else {
                        this.isData = true
                        this.subTopicData.push({
                            SUBTOPIC_ID: 0,
                            SUBJECT_ID: this.SUBJECT_ID,
                            PATTERN_ID: this.PATTERN_ID,
                            CRITERIA_ID: i.PatternCriteriaKeyId.CRITERIA_ID,
                            CHOICE_ID: 0,
                            WEIGHT: i.WEIGHT,
                            WEIGHT_SCORE: 0,
                        })
                    }
                }
            }

        });
    }

    async Save(e) {
        // if (!this.form.instance.validate().isValid) {
        //     showDialog({
        //         type: "error",
        //         title: "เกิดข้อผิดพลาด!",
        //         message: "กรุณากรอกข้อมูลให้ครบถ้วน",
        //     });
        //     return;
        // }

        e.startWait();
        console.log('subTopicData : ', this.subTopicData)
        await this.serviceSubtopic.PostSubtopic(this.subTopicData)
            .pipe(finalize(() => e.stopWait()))
            .subscribe(_ => {
                showDialog({
                    type: "info",
                    title: "สำเร็จ!",
                    message: "บันทึกเรียบร้อย",
                })
                this.router.navigate(["/setting-subject-registration"])
                this.mainForm.dataSource.reload();
                this.clearForm();
            });
    }

    public clearForm() {
        this.form.instance.resetValues();
    }

    Back() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                SUBJECT_ID:this.SUBJECT_ID,
            }
          };
        this.router.navigate(["/setting-subject-registration"],navigationExtras);
    }

    onTextareaChange(e, event){
        console.log(e)
        const indexGroupData = this.groupData.findIndex( item => item.PatternCriteriaKeyId.CRITERIA_ID == e.PatternCriteriaKeyId.CRITERIA_ID)
        this.groupData[indexGroupData].ANS_DESC = event.target.value
    }

    onRadioButtonChange(e, event){

        // console.log(e)
        const criteria_data = this.groupData.filter( item => item.PatternCriteriaKeyId.CRITERIA_ID == e.PatternCriteriaChoiceKeyId.CRITERIA_ID)
        const cal_score = (criteria_data[0].WEIGHT * e.SCORE_VALUE) / 100
        const indexGroupData = this.groupData.findIndex( item => item.PatternCriteriaKeyId.CRITERIA_ID == e.PatternCriteriaChoiceKeyId.CRITERIA_ID)
        const indexChoice = this.groupData[indexGroupData].choice.findIndex( item => item.PatternCriteriaChoiceKeyId.CHOICE_ID == e.PatternCriteriaChoiceKeyId.CHOICE_ID)
        const indexSubTopicData = this.subTopicData.findIndex( item => item.CRITERIA_ID == e.PatternCriteriaChoiceKeyId.CRITERIA_ID)
        this.groupData[indexGroupData].WEIGHT_SCORE = cal_score
        this.groupData[indexGroupData].choice = this.groupData[indexGroupData].choice.map(item => { return { ...item, SELECT_CHOICE:""} })
        this.groupData[indexGroupData].choice[indexChoice].SELECT_CHOICE = event.target.checked? "checked":""
        this.subTopicData[indexSubTopicData].WEIGHT_SCORE = cal_score
        this.subTopicData[indexSubTopicData].CHOICE_ID = this.groupData[indexGroupData].choice[indexChoice].PatternCriteriaChoiceKeyId.CHOICE_ID
        this.SUM_SCORE += e.SCORE_VALUE
        this.SUM_WEIGHT += cal_score
        console.log(this.SUM_SCORE)
        console.log(this.SUM_WEIGHT)
    }

}
