import { IPostcodeInfo, PostcodeService } from '../../../../services/postcode.service';
import { CommonModule } from '@angular/common';
import { Router,NavigationExtras } from '@angular/router';
import { Component, ViewChild, type OnInit, Input } from '@angular/core';
import { User } from 'src/app/services/user';
import DataSource from 'devextreme/data/data_source';
import { DxFormComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { PrioritizationService, IPrioritizationInfo } from 'src/app/services/prioritization.service';
import { finalize } from 'rxjs/operators';
import { showDialog } from 'src/app/common/dialog/dialog';
import { SubjectRegistrationComponent } from '../subject-registration.component';
import { GroupService, IGroupInfo, GroupFilterParam } from 'src/app/services/group.service';
import { SubjectService, ISubjectInfo, SubjectFilterParam } from 'src/app/services/subject.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-subject-registration-edit',
    templateUrl: './subject-registration-edit.component.html',
    styleUrls: ['./subject-registration-edit.component.scss'],
})
export class SubjectRegistrationEditComponent implements OnInit {

    @ViewChild(DxFormComponent, { static: true }) form: DxFormComponent;
    @ViewChild('select') select: DxSelectBoxComponent;
    @Input() formSubject: ISubjectInfo;
    public mainForm: SubjectRegistrationComponent;

    dataSourceGroup: DataSource; //DataSource;
    _filter: GroupFilterParam = {};
    _isLoading = false;
    _index = 0;
    // formSubject: ISubjectInfo;
    name = "";
    checkerList = [{ display: "ทดสอบ", value: 0 },];
    patterList = {}
    INDEX = ""
    patternData = [];
    dataCriteria = null
    dataSourceGroupSelect = null
    prioritization: IPrioritizationInfo[];
    formGroup: IGroupInfo;
    currentDate = new Date();
    currentYear = ""
    disabled: boolean;

    AUDIT_ORGANIZE = [
        {
            AUDIT_ORGANIZE_ID: 1,
            AUDIT_ORGANIZE_NAME: "สำนักตรวจเงินแผ่นดินที่ 5 (กระทรวงการคลัง 3)"
        }
    ]
    APP_INFO = [
        {
            APP_INFO_ID: 159,
            APP_INFO_NAME: "ระบบตรวจสอบผลสัมฤทธิ์และประสิทธิภาพการดำเนินงาน"
        }
    ]
    MAIN_ORGANIZE = [
        {
            MAIN_ORGANIZE_ID: 1,
            MAIN_ORGANIZE_NAME: "กลุ่มตรวจสอบที่ 1"
        }
    ]
    PLNY_TEAM = [
        {
            PLNY_TEAM_ID: 1,
            PLNY_TEAM_NAME: "หน่วยตรวจรับที่ 1"
        }
    ]
    PLNY_LAYER = [
        {
            PLNY_LAYER_CODE: 1,
            PLNY_LAYER_NAME: "กลุ่มภารกิจที่ 1"
        }
    ]

    constructor(
        private servicePrioritization: PrioritizationService,
        private serviceGroup: GroupService,
        private route: ActivatedRoute,
        private serviceSubject: SubjectService,
        private router: Router) {
        this.formSubject = {} as any;
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    async ngOnInit() {
        this._isLoading = true;
        this.formGroup = {}
        this.currentYear = String(this.currentDate.getFullYear()+543);
        this.formSubject.BUDGET_YEAR = this.currentYear
        this.formSubject.AUDIT_ORGANIZE_ID = 1
        this.formSubject.APP_INFO_ID = 159

        this.patternData = await this.serviceGroup
            .GetGroup({
                requireTotalCount: true,
                patternName: this._filter.patternName,
                recordStatus: false,
            })
            .toPromise()
            .then((data: any) => data.Data)
            .catch(error => {
                console.log(error)
                this._isLoading = false;
                throw new Error('Data Loading Error');
            });

        // console.log('patternData : ',this.patternData)
        this._isLoading = false;
    }

    async Load() {
        this._isLoading = true;
        const data = await this.serviceSubject
            .GetSubject({
                requireTotalCount: true,
                offset: 0,
                length: 20,
                recordStatus: false,
            })
            .toPromise()
            .then((data: any) => ({
                data: data.Data,
                totalCount: data.TotalCount,
            }))
            .catch(error => {
                console.log(error)
                this._isLoading = false;
                throw new Error('Data Loading Error');
            });

        this._isLoading = false;
        console.log('subjectnData : ',data)
    }

    onEdit(data, index){
        // console.log("data:",data)
        this.formSubject = data;
        this.INDEX = (index+1).toString()
        if (this.formSubject.SUBJECT_ID && this.formSubject.SUBJECT_ID != 0) {
            this.disabled = true;
        }
    }

    createForm(){
        const patternFilter_ = this.patternData.filter( e => e.PATTERN_ID === this.formSubject.PATTERN_ID)
        this.mainForm.multiView.selectedIndex = 2;
        const navigationExtras: NavigationExtras = {
            state: {
                data: patternFilter_[0]
            },
            queryParams: {
                PATTERN_ID:patternFilter_[0].PATTERN_ID,
                PATTERN_NAME:patternFilter_[0].PATTERN_NAME,
                OPINION_FLAG:patternFilter_[0].OPINION_FLAG,
                RATING_FLAG:patternFilter_[0].RATING_FLAG,
                WEIGHT_FLAG:patternFilter_[0].WEIGHT_FLAG,
                BUDGET_YEAR:this.formSubject.BUDGET_YEAR,
                SUBJECT_ID:this.formSubject.SUBJECT_ID,
                SUBJECT_INFO:this.formSubject.SUBJECT_INFO,
                APP_INFO_NAME:"ระบบตรวจสอบผลสัมฤทธิ์และประสิทธิภาพการดำเนินงาน",
            }
          };
        this.router.navigate(["/setting-subject-registration-view"],navigationExtras);
    }

    Save(e) {
        if (!this.form.instance.validate().isValid) {
            showDialog({
                type: "error",
                title: "เกิดข้อผิดพลาด!",
                message: "กรุณากรอกข้อมูลให้ครบถ้วน",
            });
            return;
        }

        e.startWait();
        if (this.formSubject.SUBJECT_ID && this.formSubject.SUBJECT_ID != 0) {
            this.serviceSubject.PutSubject(this.formSubject.SUBJECT_ID, {
                SUBJECT_ID : this.formSubject.SUBJECT_ID,
                BUDGET_YEAR : this.formSubject.BUDGET_YEAR,
                AUDIT_ORGANIZE_ID : this.formSubject.AUDIT_ORGANIZE_ID,
                APP_INFO_ID : this.formSubject.APP_INFO_ID,
                SUBJECT_INFO : this.formSubject.SUBJECT_INFO,
                SUBJECT_DESC : this.formSubject.SUBJECT_DESC,
                MAIN_ORGANIZE_ID : this.formSubject.MAIN_ORGANIZE_ID,
                PLNY_TEAM_ID : this.formSubject.PLNY_TEAM_ID,
                PLNY_LAYER_CODE : this.formSubject.PLNY_LAYER_CODE,
                PATTERN_ID : this.formSubject.PATTERN_ID
            })
            .pipe(finalize(() => e.stopWait()))
            .subscribe(_ => {
                this.mainForm.multiView.selectedIndex = 0;
                this.mainForm.dataSource.reload();
                showDialog({
                    type: "info",
                    title: "สำเร็จ!",
                    message: "บันทึกเรียบร้อย",
                })
                this.clearForm();
            });
        } else {
            this.serviceSubject.PostSubject({
                SUBJECT_ID : 0,
                BUDGET_YEAR : this.formSubject.BUDGET_YEAR,
                AUDIT_ORGANIZE_ID : this.formSubject.AUDIT_ORGANIZE_ID,
                APP_INFO_ID : this.formSubject.APP_INFO_ID,
                SUBJECT_INFO : this.formSubject.SUBJECT_INFO,
                SUBJECT_DESC : this.formSubject.SUBJECT_DESC,
                MAIN_ORGANIZE_ID : this.formSubject.MAIN_ORGANIZE_ID,
                PLNY_TEAM_ID : this.formSubject.PLNY_TEAM_ID,
                PLNY_LAYER_CODE : this.formSubject.PLNY_LAYER_CODE,
                PATTERN_ID : this.formSubject.PATTERN_ID
            })
            .pipe(finalize(() => e.stopWait()))
            .subscribe(_ => {
                this.mainForm.multiView.selectedIndex = 0;
                showDialog({
                    type: "info",
                    title: "สำเร็จ!",
                    message: "บันทึกเรียบร้อย",
                })
                this.mainForm.dataSource.reload();
                this.clearForm();
            });
        }
    }

    async OnGroupSelected(event: any) {
        const dataArray = this.patternData
        const index = dataArray.findIndex(e => e.PATTERN_ID === event.value);
        const dataPattern = dataArray[index]
        // console.log('dataPattern : ',dataPattern)
        this.dataSourceGroupSelect = dataPattern;
        this.dataCriteria = await this.servicePrioritization
            .GetPrioritization({
                requireTotalCount: true,
                patternid: dataPattern.PATTERN_ID,
                recordStatus: false
            })
            .toPromise()
            .then((data: any) => ({
                data: data.Data,
                totalCount: data.TotalCount,
            }))
            .catch(error => {
                console.log(error)
                this._isLoading = false;
                throw new Error('Data Loading Error');
            });

        this.prioritization = this.dataCriteria.data
        // console.log('prioritization : ',this.prioritization)
    }

    public clearForm() {
        this.form.instance.resetValues();
    }

    cancel() {
        window.location.reload()
    }

    Back() {
        // this.clearForm();
        this.mainForm.multiView.selectedIndex = 0;
    }
}
