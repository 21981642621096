import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { req } from '../common/betimes-http-request';
import { trimObject } from '../common/helper';
import { IPagingResult, OffsetFilterParam } from '../common/common-type';
import DataSource from 'devextreme/data/data_source';

@Injectable({
    providedIn: 'root'
})
export class PrioritizationService {

    [x: string]: any;

    constructor() { }

    public GetPrioritization(filter?: PrioritizationFilterParam): Observable<IPagingResult<IPrioritizationInfo>> {
        trimObject(filter);
        return req<IPagingResult<IPrioritizationInfo>>('/moscriteria')
            .queryString( filter as any)
            .get();
    }

    public GetPrioritizationId(id: number): Observable<IPagingResult<IPrioritizationInfo>> {
        return req<IPagingResult<IPrioritizationInfo>>('/moscriteria/pattern/'+ id).get();
    }

    public PostPrioritization(param: any): Observable<IPrioritizationInfo> {
        return req<IPrioritizationInfo>('/moscriteria').body(param).post();
    }

    public PutPrioritization(id: number, param: IPrioritizationInfo): Observable<IPrioritizationInfo> {
        return req<IPrioritizationInfo>('/moscriteria/' + id).body(param).put();
    }
}

export interface IPrioritizationInfo {
    PatternCriteriaKeyId: {
        PATTERN_ID?: number;
        CRITERIA_ID?: number;
        PATTERN_NAME?: string; // ชื่อรูปการประเมินคัดเลือก
    },
    CRITERIA_NAME?: string; // หัวข้อ
    CRITERIA_DESC?: string; // คำอธิบาย
    WEIGHT?: number; // คะแนนถ่วง นน
    CHOICE_QTY?: number; // จำนวนข้อ (ระดับความเสี่ยง)
}

export interface PrioritizationFilterParam extends OffsetFilterParam {
    patternid?: number;
}
