<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="panel-body mb-2">
    <div class="col-md-12 text-right">
        <dx-button
            icon="revert"
            class="back-button btn-size"
            hint="ย้อนกลับ"
            text="ย้อนกลับ"
            (onClick)="Back()"
        >
        </dx-button>
        <dx-button-improve
            id="dx-button-improv"
            class="save-button btn-size"
            icon="floppy"
            text="บันทึก"
            (clicked)="Save($event)"
            [useSubmitBehavior]="true"
        >
        </dx-button-improve>
    </div>
</div>
<div class="card mb-2">
    <div class="card-body">
        <div class="panel panel-default">
            <div class="panel-body">
                <dx-form [formData]="formOrganize" [colCount]="2">
                    <!-- ลำดับ -->
                    <dxi-item
                        dataField="ORGANIZE_ARIA_CODE"
                        editorType="dxTextBox"
                        [colSpan]="2"
                        [editorOptions]="{
                            placeholder: 'ลำดับ',
                            showClearButton: true
                        }"
                    >
                        <dxo-label text="ลำดับ"></dxo-label>
                    </dxi-item>


                    <!-- หัวข้อ ITGC ( ภาษาไทย ) -->
                    <dxi-item
                        dataField="ORGANIZE_SEQ"
                        editorType="dxTextBox"
                        [colSpan]="2"
                        [editorOptions]="{
                            placeholder: 'หัวข้อ ITGC ( ภาษาไทย )',
                            showClearButton: true
                        }"
                    >
                        <dxo-label
                            text="หัวข้อ ITGC ( ภาษาไทย )"
                        ></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="กรุณากรอกข้อมูล"
                        >
                        </dxi-validation-rule>
                    </dxi-item>
                    <!-- หัวข้อ ITGC ( ภาษาอังกฤษ ) -->
                    <dxi-item
                        dataField="ORGANIZE_SEQ"
                        editorType="dxTextBox"
                        [colSpan]="2"
                        [editorOptions]="{
                            placeholder: 'หัวข้อ ITGC ( ภาษาอังกฤษ )',
                            showClearButton: true
                        }"
                    >
                        <dxo-label
                            text="หัวข้อ ITGC ( ภาษาอังกฤษ )"
                        ></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="กรุณากรอกข้อมูล"
                        >
                        </dxi-validation-rule>
                    </dxi-item>
                    <!-- วัตถุประสงค์ -->
                    <dxi-item
                        dataField="ORGANIZE_NAME_DETAIL"
                        editorType="dxTextArea"
                        [colSpan]="2"
                        [editorOptions]="{
                            placeholder: 'วัตถุประสงค์',
                            showClearButton: true
                        }"
                    >
                        <dxo-label text="วัตถุประสงค์"></dxo-label>
                    </dxi-item>
                    <!-- จำนวนข้อหมวดหมู่ -->
                    <dxi-item
                        dataField="ORGANIZE_SEQ"
                        editorType="dxTextBox"
                        [colSpan]="2"
                        [editorOptions]="{
                            placeholder: 'จำนวนข้อหมวดหมู่',
                            showClearButton: true
                        }"
                    >
                        <dxo-label
                            text="จำนวนข้อหมวดหมู่"
                        ></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="กรุณากรอกข้อมูล"
                        >
                        </dxi-validation-rule>
                    </dxi-item>
                    <!-- จำนวนข้อการประเมิน -->
                    <dxi-item
                        dataField="ORGANIZE_SEQ"
                        editorType="dxTextBox"
                        [colSpan]="2"
                        [editorOptions]="{
                            placeholder: 'จำนวนข้อการประเมิน',
                            showClearButton: true
                        }"
                    >
                        <dxo-label
                            text="จำนวนข้อการประเมิน"
                        ></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="กรุณากรอกข้อมูล"
                        >
                        </dxi-validation-rule>
                    </dxi-item>
                </dx-form>
            </div>
        </div>
    </div>
</div>
