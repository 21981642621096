<label for="" class="mb-2">ชื่อรูปแบบการประเมินคัดเลือก</label>
                    <dx-text-box class="mb-3" dataField="" [readOnly]="true"  [(value)]="PATTERN_NAME"></dx-text-box>

<dx-form [formData]="formPrioritization" labelLocation="top" [colCount]="1">
    <!-- ลำดับ -->
    <dxi-item dataField="PatternCriteriaKeyId.CRITERIA_ID" editorType="dxTextBox" [editorOptions]="{ placeholder: 'ลำดับ', showClearButton: true, width: '100px', readOnly: true}">
        <dxo-label text="ลำดับ"></dxo-label>
    </dxi-item>
    <dxi-item dataField="" editorType=""></dxi-item>
    <!-- หัวข้อเรื่อง -->
    <dxi-item dataField="CRITERIA_NAME" editorType="dxTextBox" [editorOptions]="{ placeholder: 'หัวข้อเรื่อง', showClearButton: true }">
        <dxo-label text="หัวข้อเรื่อง"/>
        <dxi-validation-rule type="required" message="กรุณากรอกข้อมูล"/>
    </dxi-item>
    <!-- คำอธิบาย -->
    <dxi-item dataField="CRITERIA_DESC" editorType="dxTextArea" [editorOptions]="{ placeholder: 'คำอธิบาย', showClearButton: true }">
        <dxo-label text="คำอธิบาย"></dxo-label>
    </dxi-item>
    <!-- น้ำหนัก -->
    <dxi-item dataField="WEIGHT" editorType="dxTextBox" [editorOptions]="{ placeholder: 'น้ำหนัก', showClearButton: true, width: '100px'}">
        <dxo-label text="น้ำหนัก"></dxo-label>
        <dxi-validation-rule type="required" message="กรุณากรอกข้อมูล"/>
    </dxi-item>
</dx-form>
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="panel-body">
    <div class="col-md-12 text-end">
        <dx-button icon="revert" class="back-button btn-size" hint="ย้อนกลับ" text="ย้อนกลับ" (onClick)="Back()" ></dx-button>
        <dx-button-improve id="dx-button-improv" class="save-button btn-size" icon="floppy" text="บันทึก" (clicked)="Save()" [useSubmitBehavior]="true"></dx-button-improve>
    </div>
</div>
