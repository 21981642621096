<div class="container-fluid">
    <div class="card">
        <dx-multi-view [swipeEnabled]="false" [selectedIndex]="_index">
            <dxi-item>
                <div class="d-flex">
                    <div class="col-12 col-md-3">
                        <div class="inputgroup">
                            <dx-text-box [(value)]="_filter.patternName" placeholder="ค้นหา" [width]="300" style=" border-top-right-radius: 0; border-bottom-right-radius: 0; border-color: #8d2621;"></dx-text-box>
                            <dx-button icon="search" class="button-inputgroup" (onClick)="search()"></dx-button>
                        </div>
                    </div>
                    <div class="ms-auto">
                        <dx-button icon="add" class="btn-add-datagrid" text="เพิ่ม" (onClick)="Add()"></dx-button>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="panel panel-default">
                        <br>
                        <dx-data-grid
                            #grid
                            [dataSource]="dataSource"
                            [showColumnLines]="true"
                            [showRowLines]="true"
                            [columnHidingEnabled]="true"
                            [showBorders]="true"
                            [rowAlternationEnabled]="true"
                            [hoverStateEnabled]="true"
                            [selectedRowKeys]="[]"
                            noDataText="ไม่มีข้อมูล"
                        >
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-editing mode="cell" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="false"></dxo-editing>
                        <dxo-remote-operations [paging]="true"></dxo-remote-operations>
                        <dxo-filter-row [visible]="false"> </dxo-filter-row>
                        <dxo-pager [showPageSizeSelector]="true" [visible]="true" [allowedPageSizes]="[10, 20, 50]" [showInfo]="true"></dxo-pager>
                        <dxo-selection mode="single"></dxo-selection>
                            <dxi-column dataField="" caption="ลำดับ" [width]="80" alignment="center" [allowEditing]="false" cellTemplate="cellTemplate">
                                <div *dxTemplate="let cell of 'cellTemplate'">
                                    {{ cell.component.pageIndex() * cell.component.pageSize() + cell.rowIndex + 1 }}
                                </div>
                            </dxi-column>
                            <dxi-column dataField="PATTERN_NAME" caption="ชื่อรูปแบบการประเมินคัดเลือก" alignment="left"></dxi-column>
                            <dxi-column dataField="CREATE_USER_ID" caption="สร้างโดย" [width]="120" alignment="center"></dxi-column>
                            <dxi-column dataField="CREATE_DATE" caption="วันที่สร้าง" [width]="120" alignment="center" dataType="date" format="dateThai"></dxi-column>
                            <dxi-column dataField="UPDATE_USER_ID" caption="แก้ไขโดย" [width]="120" alignment="center"></dxi-column>
                            <dxi-column dataField="UPDATE_DATE" caption="วันที่แก้ไข" [width]="120" alignment="center" dataType="date" format="dateThai"></dxi-column>
                            <dxi-column dataField="" caption=" " [width]="120" alignment="center" [allowSorting]="false" cellTemplate="cellTemplateCommandEdit"></dxi-column>
                            <div *dxTemplate="let d of 'cellTemplateCommandEdit'">
                                <dx-button class="btn-add-datagrid mx-1" icon="edit" requirePermission="EDIT" hint="แก้ไข" type="default" (onClick)="Edit(d)"></dx-button>
                                <dx-button class="btn-add-datagrid mx-1" icon="eyeopen" requirePermission="VIEW" hint="รายละเอียด" type="default" (onClick)="View(d)"></dx-button>
                            </div>

                            <div *dxTemplate="let cell of 'cellTemplate'">
                                {{ cell.component.pageIndex() * cell.component.pageSize() + cell.rowIndex + 1 }}
                            </div>
                        </dx-data-grid>
                    </div>
                </div>
            </dxi-item>
            <dxi-item>
                <app-group-edit [formData]="formAssessment"></app-group-edit>
            </dxi-item>
        </dx-multi-view>

    </div>
</div>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="_isLoading" [showIndicator]="true" [showPane]="true"
    [shading]="true" [closeOnOutsideClick]="false" message="กำลังโหลดข้อมูล..." shadingColor="rgba(0, 0, 0, 0.2)">
</dx-load-panel>
