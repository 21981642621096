import { Component, ViewChild, type OnInit } from '@angular/core';
import { DxMultiViewComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { Router,NavigationExtras } from '@angular/router';
import { GroupEditComponent } from './group-edit/group-edit.component';
import { GroupViewComponent } from './group-view/group-view.component';
import { User } from 'src/app/services/user';
import { showConfirmDialog, showDialog } from 'src/app/common/dialog/dialog';
import { GroupService, IGroupInfo, GroupFilterParam } from 'src/app/services/group.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-group-management',
    templateUrl: './group-management.component.html',
    styleUrls: ['./group-management.component.scss'],
})
export class GroupManagementComponent implements OnInit {

    @ViewChild(GroupEditComponent, { static: true }) form: GroupEditComponent;
    @ViewChild(GroupViewComponent, { static: true }) formView: GroupViewComponent;
    @ViewChild(DxMultiViewComponent, { static: true }) multiView: DxMultiViewComponent;

    dataSource: DataSource; //DataSource;

    _filter: GroupFilterParam = {};
    _isLoading = false;
    _index = 0;

    // public formAssessment_mock: IGroupInfo[];
    public formAssessment: IGroupInfo;

    constructor(private serviceGroup: GroupService, private router: Router) {
        this.formAssessment = {} as any;
    }

    ngOnInit() {
        // console.log(this.form)
        this.form.mainForm = this;
        this.Load();
    }

    async Load() {
        this._isLoading = true;

        // this.formAssessment_mock = [{
        //     PATTERN_ID: 1,
        //     PATTERN_NAME: "การคัดเลือกเรื่องเพื่อตรวจสอบผลสัมฤทธิ์และประสิทธิภาพการดำเนินการ",
        //     PATTERN_DESC: "การคัดเลือกเรื่องเพื่อตรวจสอบผลสัมฤทธิ์และประสิทธิภาพการดำเนินการ",
        //     CRITERIA_QTY: 8,
        //     CHONLY_FLAG: "Y",
        //     OPINION_FLAG: "Y",
        //     RATING_FLAG: "Y",
        //     WEIGHT_FLAG: "Y",
        //     RECORD_STATUS: "A",
        //     CREATE_USER_ID: "นายสมชาย ทดสอบ",
        //     CREATE_DATE: "17 พ.ย. 2566",
        //     UPDATE_USER_ID: "นายสมชาย ทดสอบ",
        //     UPDATE_DATE: "17 พ.ย. 2566",
        // }]

        this.dataSource = await new DataSource({
            byKey: (key) => key,
            load: (opt) => {
                return this.serviceGroup
                    .GetGroup({
                        requireTotalCount: opt.requireTotalCount,
                        offset: opt.skip,
                        length: opt.take,
                        patternName: this._filter.patternName,
                        recordStatus: false,
                    })
                    .toPromise()
                    .then((data: any) => ({
                        data: data.Data,
                        totalCount: data.TotalCount,
                    }))
                    .catch(() => {
                        this._isLoading = false;
                        throw new Error('Data Loading Error');
                    });
            },
        });

        console.log('dataSource : ',this.dataSource)
        this._isLoading = false;

    }

    search() {
        this.dataSource.reload();
    }

    Add() {
        this.multiView.selectedIndex = 1;
    }

    Edit(e) {
        this.formAssessment = Object.assign({}, e.data);
        this.multiView.selectedIndex = 1;
        this.form.onEdit(e.data, e.rowIndex)
    }

    View(e) {
        // this.formAssessment = Object.assign({}, e.data);
        this.multiView.selectedIndex = 2;
        const navigationExtras: NavigationExtras = {
            state: {
                data: e.data
            },
            queryParams: {
                PATTERN_ID:e.data.PATTERN_ID,
                PATTERN_NAME:e.data.PATTERN_NAME,
                OPINION_FLAG:e.data.OPINION_FLAG,
                RATING_FLAG:e.data.RATING_FLAG,
                WEIGHT_FLAG:e.data.WEIGHT_FLAG
            }
          };
        this.router.navigate(["/setting-app-group-view"],navigationExtras);

    }

}
