import { Component, ViewChild, type OnInit } from '@angular/core';
import { DxMultiViewComponent } from 'devextreme-angular';
import { ScoreEditComponent } from './score-edit/score-edit.component';
import DataSource from 'devextreme/data/data_source';
import { IChoiceInfo } from 'src/app/services/choice.service';
import { ChoiceService } from 'src/app/services/choice.service';
import { User } from 'src/app/services/user';
import { showConfirmDialog, showDialog } from 'src/app/common/dialog/dialog';
import { PrioritizationService, IPrioritizationInfo } from 'src/app/services/prioritization.service';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Router,NavigationExtras } from '@angular/router';

@Component({
    selector: 'app-score-management',
    templateUrl: './score-management.component.html',
    styleUrls: ['./score-management.component.scss'],
})
export class ScoreManagementComponent implements OnInit {

    @ViewChild(ScoreEditComponent, { static: true }) form: ScoreEditComponent;
    @ViewChild(DxMultiViewComponent, { static: true }) multiView: DxMultiViewComponent;

    dataSource: DataSource; //DataSource;

    // choice: IChoiceInfo[] = {} as any;
    _isLoading = false;
    _index = 0;
    choice: IChoiceInfo[];

    formPrioritization: IPrioritizationInfo;

    dataSourceGroup = []
    CREATE = false;

    PATTERN_ID = "";
    PATTERN_NAME = "";
    CRITERIA_ID = "";
    CRITERIA_NAME = "";
    CHOICE_QTY = "";
    SELECT = 1;

    INDEX = 0;
    CHOICE_ID = 0;
    CHOICE_NAME = "";
    CHOICE_DESC = "";
    SCORE_VALUE = "";

    public formChoice: IChoiceInfo;

    constructor(
        private servicePrioritization: PrioritizationService,
        private serviceChoice: ChoiceService,
        private route: ActivatedRoute,
        private router: Router) {
        this.formChoice = {} as any;
    }

    async ngOnInit() {
        // this.form.mainForm = this;
        this.Load();
        this.route.queryParamMap.subscribe(async params => {
            const patternId = params.get('PATTERN_ID');
            const criteriaId = params.get('CRITERIA_ID');
            const data = await this.servicePrioritization
                .GetPrioritization({
                    requireTotalCount: true,
                    offset: 0,
                    length: 20,
                    patternid: Number(patternId),
                    recordStatus: false
                })
                .toPromise()
                .then((data: any) => ({
                    data: data.Data,
                    totalCount: data.TotalCount,
                }))
                .catch(error => {
                    console.log(error)
                    this._isLoading = false;
                    throw new Error('Data Loading Error');
                });
            console.log(data.data)
            this.dataSourceGroup = data.data.map(e=> {return {value:e.PatternCriteriaKeyId.PATTERN_ID,label:e.CRITERIA_NAME}})
            const result = data.data.filter(e => e.PatternCriteriaKeyId.PATTERN_ID == patternId && e.PatternCriteriaKeyId.CRITERIA_ID == criteriaId)
            this.PATTERN_ID = params.get('PATTERN_ID');
            this.PATTERN_NAME= params.get('PATTERN_NAME');
            this.CRITERIA_ID=result[0].PatternCriteriaKeyId.CRITERIA_ID;
            this.CRITERIA_NAME=result[0].CRITERIA_NAME;
            this.formPrioritization = {
                PatternCriteriaKeyId: {
                    PATTERN_ID: Number(patternId),
                    CRITERIA_ID: result[0].PatternCriteriaKeyId.CRITERIA_ID,
                    PATTERN_NAME: this.PATTERN_NAME, // ชื่อรูปการประเมินคัดเลือก
                },
                CRITERIA_NAME: result[0].CRITERIA_NAME, // หัวข้อ
                CRITERIA_DESC: result[0].CRITERIA_DESC, // คำอธิบาย
                WEIGHT: result[0].WEIGHT, // คะแนนถ่วง นน
                CHOICE_QTY: result[0].CHOICE_QTY, // จำนวนข้อ (ระดับความเสี่ยง)};
            }

            const groupChioce = await this.serviceChoice.GetChoiceCriteria(Number(this.CRITERIA_ID))
                .toPromise()
                .then((data: any) => ({
                    data: data,
                }))
                .catch(error => {
                    console.log(error)
                    this._isLoading = false;
                    throw new Error('Data Loading Error');
                });

                console.log('groupChioce : ',groupChioce)

            const newChioce = groupChioce.data.filter(e => e.PatternCriteriaChoiceKeyId.PATTERN_ID == Number(patternId) )
            this.CHOICE_QTY = String(newChioce.length)
            console.log('newChioce ',newChioce)
            this.choice = newChioce
        });

    }

    OnGroupSelected(e) {

    }

    async Load() {
        this._isLoading = true;

        // this.dataSource = new DataSource({
        //     load: () => this.serviceGroup
        //         .GetGroup()
        //         .toPromise()
        //         .then(_ => ({ data: _ }))
        // });
        // this._isLoading = false;

        this.dataSource = await new DataSource({
            byKey: (key) => key,
            load: (opt) => {
                return this.serviceChoice
                    .GetChoice()
                    .toPromise()
                    .then((data: any) => ({
                        data: data.Data,
                        totalCount: data.TotalCount,
                    }))
                    .catch(() => {
                        this._isLoading = false;
                        throw new Error('Data Loading Error');
                    });
            },
        });

        this._isLoading = false;

    }

    search() {

    }

    Edit(e) {
        this.formChoice = Object.assign({}, e.data);
        this.multiView.selectedIndex = 1;
        // this.form.onEdit(e.data)
        console.log(this.formChoice)
        this.INDEX = this.choice.findIndex(item => item.PatternCriteriaChoiceKeyId.CHOICE_ID === e.data.PatternCriteriaChoiceKeyId.CHOICE_ID);
    }

    SaveEdit() {
        this.choice[this.INDEX].CHOICE_NAME = this.formChoice.CHOICE_NAME;
        this.choice[this.INDEX].CHOICE_DESC = this.formChoice.CHOICE_DESC;
        this.choice[this.INDEX].SCORE_VALUE = Number(this.formChoice.SCORE_VALUE);
        this.multiView.selectedIndex = 0;
    }

    Save(e) {
        console.log(this.CREATE)
        if(this.CREATE){
            this.choice = this.choice.map(e=> {return {...e,PatternCriteriaChoiceKeyId:{...e.PatternCriteriaChoiceKeyId,CHOICE_ID:0}}})
        }

        console.log(this.choice)

        e.startWait();
        this.serviceChoice.PostChoice(this.choice)
            .pipe(finalize(() => e.stopWait()))
            .subscribe(_ => {
                this.multiView.selectedIndex = 0;
                showDialog({
                    type: "info",
                    title: "สำเร็จ!",
                    message: "บันทึกเรียบร้อย",
                })
                this.dataSource.reload();
                this.clearForm();
            });

    }

    GenRisk() {
        const array = [];
        this.CREATE = true;
        for(let i = 0 ; i < Number(this.CHOICE_QTY) ; i++){
            array.push({
                PatternCriteriaChoiceKeyId : {
                    PATTERN_ID: this.formPrioritization?.PatternCriteriaKeyId.PATTERN_ID,
                    CRITERIA_ID: this.formPrioritization?.PatternCriteriaKeyId.CRITERIA_ID,
                    CHOICE_ID: i+1,
                },
                CHOICE_NAME: "",
                CHOICE_DESC: "",
                SCORE_VALUE: "",
            })
        }
        this.choice = array;
    }

    public clearForm() {

    }

    Back() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                PATTERN_ID:this.PATTERN_ID,
            }
          };
        this.router.navigate(["/setting-prioritization"],navigationExtras);
    }

}
