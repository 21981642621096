import { Component } from '@angular/core';

@Component({
    selector: 'app-not-found-menu-page',
    templateUrl: './not-found-menu-page.component.html',
    styleUrls: ['./not-found-menu-page.component.scss']
})
export class NotFoundMenuPageComponent {

    constructor() { }

}
