<div class="container-fluid">
    <div class="card">
        <dx-multi-view [swipeEnabled]="false" [selectedIndex]="0">
            <dxi-item>
                <div class="card-body ">
                    <div class="panel panel-default ">
                        <div class="panel-heading">
                            <!-- appinfo -->
                            <div class="row justify-content-md-center font-kanit-all">
                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                    <dx-select-box #select [dataSource]="appinfo" displayExpr="APP_NAME"
                                        valueExpr="APP_ID" placeholder="Module" [(value)]="formData.APP_ID"
                                        (onValueChanged)="OnAppSelected($event)">
                                    </dx-select-box>
                                </div>
                            </div>
                            <div style="text-align: right;padding-top: 10px;">
                                <dx-button icon="add" class="btn-add-datagrid" text="เพิ่ม" (onClick)="Add()">
                                </dx-button>
                            </div>
                        </div>
                        <br>
                        <dx-tree-list class="table-module-info" #grid [dataSource]="moduleInfo" [showColumnLines]="true"
                            [showRowLines]="true" keyExpr="MODULE_ID" parentIdExpr="MODULE_PARENT_ID"
                            [autoExpandAll]="true" [columnHidingEnabled]="true" [showBorders]="true"
                            [rowAlternationEnabled]="true" [hoverStateEnabled]="true" [selectedRowKeys]="[]"
                            noDataText="ไม่มีข้อมูล">
                            <dxo-scrolling mode="standard"></dxo-scrolling>
                            <dxo-paging [pageSize]="15"></dxo-paging>
                            <dxo-editing mode="cell" [allowUpdating]="false" [allowDeleting]="false"
                                [allowAdding]="false">
                            </dxo-editing>
                            <!-- <dxo-filter-row [visible]="true">
                            </dxo-filter-row> -->
                            <dxo-search-panel [visible]="true" [width]="240" placeholder="ค้นหา..."></dxo-search-panel>
                            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50]"
                                [showInfo]="true">
                            </dxo-pager>
                            <dxo-selection mode="single"></dxo-selection>
                            <dxi-column dataField="MODULE_NAME" caption="ชื่อเมนู"
                                cellTemplate="cellTemplateCommandsIcon">
                            </dxi-column>
                            <dxi-column dataField="MODULE_SEQ" caption="ลำดับเมนู" alignment="center" [width]="100" [allowFiltering]="false">
                            </dxi-column>
                            <dxi-column dataField="MODULE_CODE" caption="รหัส" alignment="center" [width]="100" [allowFiltering]="false">
                            </dxi-column>
                            <dxi-column dataField="" caption="เพิ่ม" [width]="80" alignment="center"
                                [allowSorting]="false" cellTemplate="cellTemplateCommandAdd"></dxi-column>
                            <div *dxTemplate="let d of 'cellTemplateCommandAdd'">
                                <dx-button icon="add" requirePermission="ADD" hint="เพิ่ม" class="btn-add-datagrid" type="success"
                                    (onClick)="addMenuChild(d)">
                                </dx-button>
                            </div>
                            <dxi-column dataField="" caption="แก้ไข" [width]="80" alignment="center"
                                [allowSorting]="false" cellTemplate="cellTemplateCommand"></dxi-column>
                            <div *dxTemplate="let d of 'cellTemplateCommand'">
                                <dx-button icon="edit" hint="แก้ไข" requirePermission="EDIT" type="default"
                                    (onClick)="Edit(d)">
                                </dx-button>
                            </div>
                            <dxi-column dataField="" caption="ลบ" [width]="100" alignment="center"
                                [allowSorting]="false" cellTemplate="cellTemplateCommands"></dxi-column>
                            <div *dxTemplate="let d of 'cellTemplateCommands'">
                                <dx-button-improve icon="trash" hint="ลบ" requirePermission="DELETE" type="danger"
                                    (clicked)="Delete($event,d)">
                                </dx-button-improve>
                            </div>
                            <div *dxTemplate="let d of 'cellTemplateCommandsIcon'">
                                <div *ngIf="d.data.MODULE_LEVEL === 1">
                                    <div>
                                        <span class="fa {{IsIconStatus(d)}}" style="font-size: 16px;">
                                        </span>
                                        <span style="padding-left: 5px;">{{IsNameStatus(d)}}</span>
                                    </div>
                                </div>

                                <div *ngIf="d.data.MODULE_LEVEL === 2">
                                    <!-- <div style="padding-left: 30px;"> -->
                                    <span class="fa {{IsIconStatus(d)}}" style="font-size: 16px;">
                                    </span>
                                    <span style="padding-left: 5px;">{{IsNameStatus(d)}}</span>
                                    <!-- </div> -->
                                </div>
                                <div *ngIf="d.data.MODULE_LEVEL === 3">
                                    <!-- <div style="padding-left: 450px;"> -->
                                    <span class="fa {{IsIconStatus(d)}}" style="font-size: 16px;">
                                    </span>
                                    <span style="padding-left: 5px;">{{IsNameStatus(d)}}</span>
                                    <!-- </div> -->
                                </div>
                            </div>
                            <div *dxTemplate="let cell of 'cellTemplate'">
                                {{ cell.component.pageIndex() * cell.component.pageSize() + cell.rowIndex +
                                1 }}
                            </div>
                        </dx-tree-list>
                    </div>
                </div>
            </dxi-item>
            <dxi-item>
                <app-module-edit [formData]="formData"></app-module-edit>
            </dxi-item>
        </dx-multi-view>
    </div>
</div>
