<div class="wrapper cm-mainContent" style=" width: 100vw !important">
    <div class="sidebar" data-color="#fff0ba" data-background-color="#FBD2780F"
        data-image="./assets/moi/img/navbar/navbar-bg.png">
        <app-sidebar (menuSelectionChanged)="onMenuSelectionChanged($event)"></app-sidebar>
        <div class="sidebar-background"
            style="background-image: url('assets/moi/img/sidebar/sidebar-bg.png'); background-color: #FBD278;"></div>
    </div>
    <div class="main-panel" id="main-content">
        <app-header></app-header>
        <dx-scroll-view>
            <div class="content pb-5" style="height: 100vh;">
                <div>
                    <app-breadcrumbs [items]="breadcrumbItems"></app-breadcrumbs>
                </div>
                <div class="row">
                    <div class="col-lg-12 cm-content">
                        <router-outlet></router-outlet>

                    </div>
                </div>
            </div>
        </dx-scroll-view>
    </div>
</div>
<app-footer></app-footer>
