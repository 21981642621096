<div class="container-fluid">
    <div class="card">
        <dx-multi-view [swipeEnabled]="false" [selectedIndex]="_index">
            <dxi-item>
                <label for="" class="mb-1">ชื่อรูปแบบการประเมินคัดเลือก:</label>
                <div class="d-flex">
                    <div class="inputgroup">
                        <dx-select-box #select [dataSource]="prioritizationData" displayExpr="PATTERN_NAME"
                            valueExpr="PATTERN_ID" placeholder="ชื่อรูปแบบการประเมินคัดเลือก" [(value)]="formGroup.PATTERN_ID"
                            (onValueChanged)="OnGroupSelected($event)" width="500">
                        </dx-select-box>
                    </div>

                    <label for="" class="mt-2" style="width: 100px;">จำนวนข้อ</label>
                    <dx-text-box dataField="" [readOnly]="true" [width]="90"  [(value)]="CRITERIA_QTY"></dx-text-box>

                    <dx-button class="btn-add-datagrid" text="ประมวลจำนวนข้อลำดับความเสี่ยง" (onClick)="GenRisk(formGroup.PATTERN_ID)"></dx-button>
                </div>
                <div class="card-body ">
                    <div class="panel panel-default">
                        <label for="" class="my-2">รายการหัวข้อการประเมิน</label>
                        <dx-data-grid #grid [dataSource]="prioritization" [showColumnLines]="true" noDataText="ไม่มีข้อมูล"
                            [showRowLines]="true" [columnHidingEnabled]="true" [showBorders]="true"
                            [rowAlternationEnabled]="true" [hoverStateEnabled]="true" [selectedRowKeys]="[]"
                            noDataText="ไม่มีข้อมูล"
                            >
                            <dxo-scrolling mode="standard"></dxo-scrolling>
                            <dxi-column dataField="" caption="ลำดับ" [width]="70" alignment="center" cellTemplate="cellTemplate">
                                <div *dxTemplate="let cell of 'cellTemplate'">
                                    {{ cell.component.pageIndex() * cell.component.pageSize() + cell.rowIndex + 1 }}
                                </div>
                            </dxi-column>
                            <dxi-column dataField="CRITERIA_NAME" caption="หัวข้อเรื่อง" [width]="350" alignment="left"/>
                            <dxi-column dataField="CRITERIA_DESC" caption="คำอธิบาย" [width]="450" alignment="left"/>
                            <dxi-column dataField="WEIGHT" caption="น้ำหนัก" [width]="130"alignment="center"/>
                            <dxi-column caption="ค่าลำดับความเสี่ยง" [width]="130" alignment="center"[allowSorting]="false" cellTemplate="cellTemplateRiskEdit"/>
                            <div *dxTemplate="let d of 'cellTemplateRiskEdit'">
                                <!-- <dx-button class="mx-1" icon="view" requirePermission="VIEW" hint="รายละเอียด" type="default" (onClick)="View(d)"/> -->
                                <dx-button class="mx-1" icon="edit" requirePermission="EDIT" hint="แก้ไข" type="default"(onClick)="Edit_Score(d)"/>
                            </div>
                            <dxi-column caption=" " alignment="center"[allowSorting]="false" cellTemplate="cellTemplateCommandEdit"/>
                            <div *dxTemplate="let d of 'cellTemplateCommandEdit'">
                                <dx-button icon="edit" requirePermission="EDIT" hint="แก้ไข" type="default"(onClick)="Edit(d)"/>
                            </div>
                            <div *dxTemplate="let cell of 'cellTemplate'">
                                {{ cell.component.pageIndex() * cell.component.pageSize() + cell.rowIndex + 1 }}
                            </div>
                        </dx-data-grid>
                    </div>
                </div>
                <div class="panel panel-default mt-3">
                    <div class="panel-body d-flex">
                        <div class="col-md-6">
                            <!-- <dx-button icon="revert" class="back-button btn-size" hint="ย้อนกลับ" text="ย้อนกลับ" (onClick)="Back()"></dx-button> -->
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex justify-content-end">
                                <dx-button icon="close" class="back-button btn-size" hint="ยกเลิก" text="ยกเลิก" (onClick)="cancel()"> </dx-button>
                                <dx-button-improve id="dx-button-improv" class="save-button btn-size" icon="floppy" text="บันทึก" (clicked)="Save($event)"></dx-button-improve>
                            </div>
                        </div>
                    </div>
                </div>
            </dxi-item>
            <dxi-item>
                <app-prioritization-edit [formData]="formPrioritization"></app-prioritization-edit>
            </dxi-item>
        </dx-multi-view>

    </div>
</div>


<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="_isLoading" [showIndicator]="true" [showPane]="true"
    [shading]="true" [closeOnOutsideClick]="false" message="กำลังโหลดข้อมูล..." shadingColor="rgba(0, 0, 0, 0.2)">
</dx-load-panel>
