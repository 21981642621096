<div class="row">
    <label for="" class="mb-3"><h5>บันทึกข้อมูลเรื่องที่จะตรวจสอบ</h5></label>
</div>
<dx-form [formData]="formSubject" labelLocation="top" [colCount]="4">
    <!-- สำนัก -->
    <dxi-item [colSpan]="2" dataField="AUDIT_ORGANIZE_ID" editorType="dxSelectBox" [editorOptions]="{ items: AUDIT_ORGANIZE, displayExpr: 'AUDIT_ORGANIZE_NAME', valueExpr: 'AUDIT_ORGANIZE_ID', placeholder: 'สำนัก', showClearButton: true, readOnly: true }">
        <dxo-label text="สำนัก"/>
    </dxi-item>
    <!-- ระบบบริหารงานตรวจสอบ -->
    <dxi-item [colSpan]="2" dataField="APP_INFO_ID" editorType="dxSelectBox" [editorOptions]="{ items: APP_INFO, displayExpr: 'APP_INFO_NAME', valueExpr: 'APP_INFO_ID', placeholder: 'ระบบบริหารงานตรวจสอบ', showClearButton: true, readOnly: true }">
        <dxo-label text="ระบบบริหารงานตรวจสอบ"/>
    </dxi-item>
    <!-- ปีงบประมาณ -->
    <dxi-item [colSpan]="1" dataField="BUDGET_YEAR" editorType="dxTextBox" [editorOptions]="{ placeholder: 'ปีงบประมาณ', showClearButton: true, readOnly: true }">
        <dxo-label text="ปีงบประมาณ"/>
    </dxi-item>
     <!-- ลำดับ -->
    <dxi-item [colSpan]="1" dataField="" editorType="dxTextBox" [editorOptions]="{ value:INDEX, placeholder: 'ลำดับ', showClearButton: true, readOnly: true}">
        <dxo-label text="ลำดับ"></dxo-label>
    </dxi-item>
    <dxi-item [colSpan]="2" dataField="" editorType=""></dxi-item>
    <!-- หน่วยตรวจรับ/เรื่อง/สัญญา/อื่นๆ -->
    <dxi-item [colSpan]="4" dataField="SUBJECT_INFO" editorType="dxTextBox" [editorOptions]="{ placeholder: 'หน่วยตรวจรับ/เรื่อง/สัญญา/อื่นๆ', showClearButton: true }">
        <dxo-label text="หน่วยตรวจรับ/เรื่อง/สัญญา/อื่นๆ"/>
    </dxi-item>
    <!-- คำอธิบาย -->
    <dxi-item [colSpan]="4" dataField="SUBJECT_DESC" editorType="dxTextArea" [editorOptions]="{ placeholder: 'คำอธิบาย', showClearButton: true }">
        <dxo-label text="คำอธิบาย"></dxo-label>
    </dxi-item>
    <!-- ทีมตรวจสอบ -->
    <dxi-item [colSpan]="2" dataField="MAIN_ORGANIZE_ID" editorType="dxSelectBox" [editorOptions]="{ items: MAIN_ORGANIZE, displayExpr: 'MAIN_ORGANIZE_NAME', valueExpr: 'MAIN_ORGANIZE_ID', placeholder: 'ทีมตรวจสอบ', showClearButton: true }">
        <dxo-label text="ทีมตรวจสอบ"></dxo-label>
    </dxi-item>
    <dxi-item [colSpan]="2" dataField="" editorType=""></dxi-item>
    <!-- หน่วยรับตรวจ -->
    <dxi-item [colSpan]="2" dataField="PLNY_TEAM_ID" editorType="dxSelectBox" [editorOptions]=" { items: PLNY_TEAM, displayExpr: 'PLNY_TEAM_NAME', valueExpr: 'PLNY_TEAM_ID', placeholder: 'หน่วยรับตรวจ', showClearButton: true }">
        <dxo-label text="หน่วยรับตรวจ"></dxo-label>
    </dxi-item>
    <!-- ด้าน/กลุ่มภารกิจที่สำคัญ -->
    <dxi-item [colSpan]="2" dataField="PLNY_LAYER_CODE" editorType="dxSelectBox" [editorOptions]="{ items: PLNY_LAYER, displayExpr: 'PLNY_LAYER_NAME', valueExpr: 'PLNY_LAYER_CODE', placeholder: 'ด้าน/กลุ่มภารกิจที่สำคัญ', showClearButton: true }">
        <dxo-label text="ด้าน/กลุ่มภารกิจที่สำคัญ"></dxo-label>
    </dxi-item>
    <!-- ที่มา -->
    <dxi-item [colSpan]="2" dataField="PATTERN_ID" editorType="dxSelectBox" [editorOptions]="{ items: patternData, displayExpr: 'PATTERN_NAME', valueExpr: 'PATTERN_ID', placeholder: 'ที่มา', showClearButton: true }">
        <dxo-label text="ที่มา"></dxo-label>
    </dxi-item>
    <dxi-item [colSpan]="2" dataField="" editorType="">
        <div style="padding-top: 18px;display: flex;">
            <dx-button [disabled]="!disabled"class="btn-add-datagrid" text="ทำแบบประเมิน" style="height:36px" (onClick)="createForm()"></dx-button>
            <ul style="color:#8d2621">
                <li>คัดเลือกจากการประเมินความเสี่ยง </li>
                <li>ร้องเรียน / จากการตรวจสอบด้านอื่น / ผู้ว่าการสั่ง ให้ตรวจสอบเฉพาะเป็นกรณี (ตรวจสอบทุกเรื่องไม่ต้องคัดเลือก)</li>
            </ul>
        </div>
    </dxi-item>
    <!-- สถานะ -->
    <dxi-item [colSpan]="1" dataField="RECORD_STATUS" editorType="dxTextBox" [editorOptions]="{ placeholder: 'รอคัดเลือก/รอสอบทาน/รออนุมัติ/อื่นๆ', showClearButton: true, readOnly: true}">
        <dxo-label text="สถานะ"></dxo-label>
    </dxi-item>
</dx-form>
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="panel-body">
    <div class="col-md-12 text-end">
        <dx-button icon="close" class="back-button btn-size" hint="ยกเลิก" text="ยกเลิก" (onClick)="Back()" ></dx-button>
        <dx-button-improve id="dx-button-improv" class="save-button btn-size" icon="floppy" text="บันทึก" (clicked)="Save($event)" [useSubmitBehavior]="true"></dx-button-improve>
    </div>
</div>
