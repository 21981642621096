<div class="mb-2">
    <label for="" class="mb-1">ลำดับ :</label>
    <dx-text-box class="" [readOnly]="true" [(value)]="INDEX" style="width: 100px"></dx-text-box>
</div>
<dx-form [formData]="formAssessment" labelLocation="top" [colCount]="1">
    <!-- ชื่อรูปแบบการประเมินคัดเลือก -->
    <dxi-item dataField="PATTERN_NAME" editorType="dxTextBox"
        [editorOptions]="{
            placeholder: 'กรุณาระบุ...',
            showClearButton: true
        }"
    >
        <dxo-label text="ชื่อรูปแบบการประเมินคัดเลือก"></dxo-label>
        <dxi-validation-rule type="required" message="กรุณากรอกข้อมูล"></dxi-validation-rule>
    </dxi-item>
    <!-- คำอธิบาย -->
    <dxi-item dataField="PATTERN_DESC" editorType="dxTextArea"
        [editorOptions]="{
            placeholder: 'กรุณาระบุ...',
            showClearButton: true
        }"
    >
        <dxo-label text="คำอธิบาย"></dxo-label>
    </dxi-item>
    <!-- จำนวนข้อการประเมิน -->
    <dxi-item dataField="CRITERIA_QTY" editorType="dxNumberBox"
        [editorOptions]="{
            placeholder: 'กรุณาระบุ...',
            showClearButton: true,
            width: '100px'
        }"
    >
        <dxo-label text="จำนวนข้อการประเมิน"></dxo-label>
        <dxi-validation-rule type="required" message="กรุณากรอกข้อมูล"></dxi-validation-rule>
    </dxi-item>
    <!-- ระดับความเสี่ยงใช้ร่วมกันกับทุกข้อ -->
    <dxi-item dataField="CHONLY_FLAG" editorType="dxRadioGroup"
        [editorOptions]="{
            value: formAssessment.CHONLY_FLAG,
            items: radioActive,
            valueExpr: 'id',
            displayExpr: 'text',
            layout: 'horizontal'
        }"
    >
        <dxo-label text="ระดับความเสี่ยงใช้ร่วมกันกับทุกข้อ"></dxo-label>
    </dxi-item>
    <!-- ใส่คำอธิบายความเห็น -->
    <dxi-item dataField="OPINION_FLAG" editorType="dxRadioGroup"
        [editorOptions]="{
            value: formAssessment.OPINION_FLAG,
            items: radioActive,
            valueExpr: 'id',
            displayExpr: 'text',
            layout: 'horizontal'
        }"
    >
        <dxo-label text="ใส่คำอธิบายความเห็น"></dxo-label>
    </dxi-item>
    <!-- มีกำหนดน้ำหนัก -->
    <dxi-item dataField="WEIGHT_FLAG" editorType="dxRadioGroup"
        [editorOptions]="{
            value: formAssessment.WEIGHT_FLAG,
            items: radioActive,
            valueExpr: 'id',
            displayExpr: 'text',
            layout: 'horizontal'
        }"
    >
        <dxo-label text="มีกำหนดน้ำหนัก"></dxo-label>
    </dxi-item>
</dx-form>
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="panel-body">
    <div class="col-md-12 text-end">
        <dx-button icon="revert" class="back-button btn-size" hint="ย้อนกลับ" text="ย้อนกลับ" (onClick)="Back()" ></dx-button>
        <dx-button-improve id="dx-button-improv" class="save-button btn-size" icon="floppy" text="บันทึก" (clicked)="Save($event)" [useSubmitBehavior]="true"></dx-button-improve>
    </div>
</div>
