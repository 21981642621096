import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { req } from '../common/betimes-http-request';
import { trimObject } from '../common/helper';
import { IPagingResult, OffsetFilterParam } from '../common/common-type';
import DataSource from 'devextreme/data/data_source';

@Injectable({
    providedIn: 'root'
})
export class ChoiceService {

    [x: string]: any;

    constructor() { }

    public GetChoice(filter?: ChoiceFilterParam): Observable<IPagingResult<IChoiceInfo>> {
        trimObject(filter);
        return req<IPagingResult<IChoiceInfo>>('/moschoice')
            .queryString( filter as any)
            .get();
    }

    public GetChoicePattern(id: number): Observable<IChoiceInfo> {
        return req<IChoiceInfo>('/moschoice/pattern/'+ id).get();
    }

    public GetChoiceCriteria(id: number): Observable<IChoiceInfo> {
        return req<IChoiceInfo>('/moschoice/criteria/'+ id).get();
    }

    public PostChoice(param: any): Observable<IChoiceInfo> {
        return req<IChoiceInfo>('/moschoice').body(param).post();
    }

    public PutChoice(id: number, param: IChoiceInfo): Observable<IChoiceInfo> {
        return req<IChoiceInfo>('/moschoice/' + id).body(param).put();
    }
}

export interface IChoiceInfo {
    PatternCriteriaChoiceKeyId : {
        PATTERN_ID?: number,
        CRITERIA_ID?: number,
        CHOICE_ID?: number,
    },
    CHOICE_NAME?: string;
    CHOICE_DESC?: string;
    SCORE_VALUE?: number;
}

export interface ChoiceFilterParam extends OffsetFilterParam {
    patternid?: number;
}
