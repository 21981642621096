import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { req } from '../common/betimes-http-request';
import { trimObject } from '../common/helper';
import { IPagingResult, OffsetFilterParam } from '../common/common-type';
import DataSource from 'devextreme/data/data_source';

@Injectable({
    providedIn: 'root'
})
export class GroupService {

    [x: string]: any;

    constructor() { }

    public GetGroup(filter?: GroupFilterParam): Observable<IPagingResult<IGroupInfo>> {
        trimObject(filter);
        return req<IPagingResult<IGroupInfo>>('/mospattern')
            .queryString( filter as any)
            .get();
    }

    public GetGroupByID(id: number): Observable<IGroupInfo> {
        return req<IGroupInfo>('/mospattern/' + id).get();
    }

    public PostGroup(param: IGroupInfo): Observable<IGroupInfo> {
        return req<IGroupInfo>('/mospattern').body(param).post();
    }

    public PutGroup(id: number, param: IGroupInfo): Observable<IGroupInfo> {
        return req<IGroupInfo>('/mospattern/' + id).body(param).put();
    }
}

export interface IGroupInfo {
    PATTERN_ID?: number;
    PATTERN_NAME?: string; // ชื่อรูปการประเมินคัดเลือก
    PATTERN_DESC?: string; // คำอธิบาย
    CRITERIA_QTY?: number; // จำนวนข้อ หัวข้อการประเมิน
    CHONLY_FLAG?: string; // ตอบการประเมินใช้ร่วมกันทุกข้อ Y = ใช่  N = ไม่ใช่
    OPINION_FLAG?: string; // ใส่คำอธิบายความเห็น Y = มี  N = ไม่มี
    RATING_FLAG?: string; // คะแนน Y = มี  N = ไม่มี
    WEIGHT_FLAG?: string; // กำหนดน้ำหนัก Y = มี  N = ไม่มี
    RECORD_STATUS?: string;
    CREATE_USER_ID?: string;
    CREATE_DATE?: string;
    UPDATE_USER_ID?: string;
    UPDATE_DATE?: string;

}

export interface GroupFilterParam extends OffsetFilterParam {
    patternName?: string;
}
