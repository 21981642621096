import { CommonModule } from '@angular/common';
import { Component, ViewChild, type OnInit, Input } from '@angular/core';
import { User } from 'src/app/services/user';
import DataSource from 'devextreme/data/data_source';
import { GroupService, IGroupInfo } from 'src/app/services/group.service';
import { DxFormComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { showDialog } from 'src/app/common/dialog/dialog';
import { GroupManagementComponent } from '../group-management.component';
import { FormCommandEventType } from 'src/app/common/common-type';
import { PrioritizationService, IPrioritizationInfo } from 'src/app/services/prioritization.service';

@Component({
    selector: 'app-group-edit',
    templateUrl: './group-edit.component.html',
    styleUrls: ['./group-edit.component.scss'],
})
export class GroupEditComponent implements OnInit {

    @ViewChild(DxFormComponent, { static: true }) form: DxFormComponent;
    @ViewChild('select') select: DxSelectBoxComponent;
    @Input() formAssessment: IGroupInfo;

    public mainForm: GroupManagementComponent;
    isView = false;
    radioActive: any = [
        { id: "Y", text: "ใช่" },
        { id: "N", text: "ไม่ใช่" }
    ];

    prioritization: IPrioritizationInfo[];
    INDEX = ""

    constructor(
        private serviceGroup: GroupService,
        private servicePrioritization: PrioritizationService) {
        this.formAssessment = {} as any;
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnInit() {}

    onEdit(data, index){
        console.log("data:",data)
        this.formAssessment = data;
        this.INDEX = index.toString()
    }

    Save(e) {
        if (!this.form.instance.validate().isValid) {
            showDialog({
                type: "error",
                title: "เกิดข้อผิดพลาด!",
                message: "กรุณากรอกข้อมูลให้ครบถ้วน",
            });
            return;
        }

        e.startWait();
        if (this.formAssessment.PATTERN_ID && this.formAssessment.PATTERN_ID != 0) {
            this.serviceGroup.PutGroup(this.formAssessment.PATTERN_ID, {
                PATTERN_ID : this.formAssessment.PATTERN_ID,
                PATTERN_NAME : this.formAssessment.PATTERN_NAME,
                PATTERN_DESC : this.formAssessment.PATTERN_DESC,
                CRITERIA_QTY : this.formAssessment.CRITERIA_QTY,
                CHONLY_FLAG : this.formAssessment.CHONLY_FLAG,
                OPINION_FLAG : this.formAssessment.OPINION_FLAG,
                RATING_FLAG : "N",
                WEIGHT_FLAG : this.formAssessment.WEIGHT_FLAG,
                RECORD_STATUS : this.formAssessment.RECORD_STATUS,
            })
            .pipe(finalize(() => e.stopWait()))
            .subscribe(_ => {
                this.mainForm.multiView.selectedIndex = 0;
                this.mainForm.dataSource.reload();
                showDialog({
                    type: "info",
                    title: "สำเร็จ!",
                    message: "บันทึกเรียบร้อย",
                })
                this.clearForm();
            });
        } else {
            this.serviceGroup.PostGroup({
                PATTERN_ID : 0,
                PATTERN_NAME : this.formAssessment.PATTERN_NAME,
                PATTERN_DESC : this.formAssessment.PATTERN_DESC,
                CRITERIA_QTY : this.formAssessment.CRITERIA_QTY,
                CHONLY_FLAG : this.formAssessment.CHONLY_FLAG,
                OPINION_FLAG : this.formAssessment.OPINION_FLAG,
                RATING_FLAG : "N",
                WEIGHT_FLAG : this.formAssessment.WEIGHT_FLAG,
                RECORD_STATUS : "A",
            })
            .pipe(finalize(() => e.stopWait()))
            .subscribe(_ => {
                this.mainForm.multiView.selectedIndex = 0;
                showDialog({
                    type: "info",
                    title: "สำเร็จ!",
                    message: "บันทึกเรียบร้อย",
                })
                this.mainForm.dataSource.reload();
                this.clearForm();
            });

            // // create criteria
            // const array = [];
            // for(let i = 0 ; i < this.formAssessment.CRITERIA_QTY ; i++){
            //     array.push({
            //         PatternCriteriaKeyId: {
            //             PATTERN_ID: 0,
            //             CRITERIA_ID: 0,
            //             PATTERN_NAME: this.formAssessment.PATTERN_NAME, // ชื่อรูปการประเมินคัดเลือก
            //         },
            //         CRITERIA_NAME: "", // หัวข้อ
            //         CRITERIA_DESC: "", // คำอธิบาย
            //         WEIGHT: 0, // คะแนนถ่วง นน
            //         CHOICE_QTY: 0, // จำนวนข้อ (ระดับความเสี่ยง)
            //     })
            // }
            // this.prioritization = array;
            // this.servicePrioritization.PostPrioritization(this.prioritization)
            //     .pipe(finalize(() => e.stopWait()))
            //     .subscribe(_ => {
            //         showDialog({
            //             type: "info",
            //             title: "สำเร็จ!",
            //             message: "บันทึกเรียบร้อย",
            //         })
            //         this.clearForm();
            //     });
        }
    }

    public clearForm() {
        this.form.instance.resetValues();
    }

    Back() {
        // this.clearForm();
        this.mainForm.multiView.selectedIndex = 0;
    }

}
