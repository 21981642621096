import { Component, ViewChild, type OnInit } from '@angular/core';
import { DxMultiViewComponent } from 'devextreme-angular';
import { Router,ActivatedRoute } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { SubjectRegistrationEditComponent } from './subject-registration-edit/subject-registration-edit.component';
import { PrioritizationService, IPrioritizationInfo } from 'src/app/services/prioritization.service';
import { GroupService, IGroupInfo, GroupFilterParam } from 'src/app/services/group.service';
import { User } from 'src/app/services/user';
import { showConfirmDialog, showDialog } from 'src/app/common/dialog/dialog';
import { finalize } from 'rxjs/operators';
import { ChoiceService } from 'src/app/services/choice.service';
import { SubjectService, ISubjectInfo, SubjectFilterParam } from 'src/app/services/subject.service';


@Component({
    selector: 'app-subject-registration',
    templateUrl: './subject-registration.component.html',
    styleUrls: ['./subject-registration.component.scss'],
})
export class SubjectRegistrationComponent implements OnInit {

    @ViewChild(SubjectRegistrationEditComponent, { static: true }) form: SubjectRegistrationEditComponent;
    @ViewChild(DxMultiViewComponent, { static: true }) multiView: DxMultiViewComponent;

    dataSource: DataSource; //DataSource;

    prioritization: IPrioritizationInfo[];
    _filter: SubjectFilterParam = {};
    _isLoading = false;
    _index = 0;
    groupData = [];
    CRITERIA_QTY = "";
    PATTERN_ID = 0;
    PATTERN_NAME = "";
    cellData = "";

    ORG_NAME = ""
    MODULE_NAME = ""

    public formSubjectRegis: ISubjectInfo;

    constructor(
        private servicePrioritization: PrioritizationService,
        private serviceGroup: GroupService,
        private serviceSubject: SubjectService,
        private router: Router,
        private route: ActivatedRoute) {
        this.formSubjectRegis = {} as any;
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnInit() {
        this.ORG_NAME = "สำนักตรวจเงินแผ่นดินที่ 5 (กระทรวงการคลัง 3)"
        this.MODULE_NAME = "ระบบตรวจสอบผลสัมฤทธิ์และประสิทธิภาพการดำเนินงาน"
        this.form.mainForm = this;
        this.Load();
    }

    async Load() {
        this._isLoading = true;

        this.dataSource = await new DataSource({
            byKey: (key) => key,
            load: (opt) => {
                return this.serviceSubject
                    .GetSubject({
                        requireTotalCount: opt.requireTotalCount,
                        offset: opt.skip,
                        length: opt.take,
                        budgetyear: this._filter.budgetyear,
                        recordStatus: false,
                    })
                    .toPromise()
                    .then((data: any) => ({
                        data: data.Data,
                        totalCount: data.TotalCount,
                    }))
                    .catch(() => {
                        this._isLoading = false;
                        throw new Error('Data Loading Error');
                    });
            },
        });

        // console.log('dataSource : ',this.dataSource)
        this._isLoading = false;

    }

    search() {
        this.dataSource.reload();
    }

    Add() {
        this.multiView.selectedIndex = 1;
    }

    Edit(e) {
        console.log(e)
        this.formSubjectRegis = Object.assign({}, e.data);
        this.multiView.selectedIndex = 1;
        this.form.onEdit(e.data, e.rowIndex)
    }

}
