<dx-form [formData]="formChoice" labelLocation="top" [colCount]="1">
    <!-- ลำดับ -->
    <dxi-item dataField="PatternCriteriaChoiceKeyId.CHOICE_ID" editorType="dxTextBox" [editorOptions]="{ placeholder: 'ลำดับ', showClearButton: true, width: '100px', readOnly: true}">
        <dxo-label text="ลำดับ"></dxo-label>
    </dxi-item>
    <dxi-item dataField="" editorType=""></dxi-item>
    <!-- ระดับความเสี่ยง -->
    <dxi-item dataField="CHOICE_NAME" editorType="dxTextBox" [editorOptions]="{ placeholder: 'ระดับความเสี่ยง', showClearButton: true }">
        <dxo-label text="ระดับความเสี่ยง"/>
        <dxi-validation-rule type="required" message="กรุณากรอกข้อมูล"/>
    </dxi-item>
    <!-- คำอธิบาย -->
    <dxi-item dataField="CHOICE_DESC" editorType="dxTextArea" [editorOptions]="{ placeholder: 'คำอธิบาย', showClearButton: true }">
        <dxo-label text="คำอธิบาย"></dxo-label>
    </dxi-item>
    <!-- ค่าคะแนน -->
    <dxi-item dataField="SCORE_VALUE" editorType="dxTextBox" [editorOptions]="{ placeholder: 'ค่าคะแนน', showClearButton: true, width: '100px'}">
        <dxo-label text="ค่าคะแนน"></dxo-label>
        <dxi-validation-rule type="required" message="กรุณากรอกข้อมูล"/>
    </dxi-item>
</dx-form>
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="panel-body">
    <div class="col-md-12 text-end">
        <dx-button icon="revert" class="back-button btn-size" hint="ย้อนกลับ" text="ย้อนกลับ" (onClick)="Back()" ></dx-button>
        <dx-button-improve id="dx-button-improv" class="save-button btn-size" icon="floppy" text="บันทึก" (clicked)="Save()" [useSubmitBehavior]="true"></dx-button-improve>
    </div>
</div>
