import { NgModule } from "@angular/core";
import { LoginComponent } from "./components/pages/login/login.component";
import { UnAuthenticationComponent } from "./components/pages/un-authentication/un-authentication.component";
import { RouterModule, Routes } from "@angular/router";
import { loginGuard } from "./guard/login.guard";
import { ModuleSettingComponent } from "./components/pages/module-setting/module-setting.component";
import { RoleSettingComponent } from "./components/pages/role-setting/role-setting.component";
import { MainPageComponent } from "./components/layout/main-page/main-page.component";
import { authGuard } from "./guard/auth-guard";
import { ProvinceSettingComponent } from "./components/pages/province-setting/province-setting.component";
import { OrgSettingComponent } from "./components/pages/org-setting/org-setting.component";
import { AppOrgSettingComponent } from "./components/pages/app-org-setting/app-org-setting.component";
import { AppSettingComponent } from "./components/pages/app-setting/app-setting.component";
import { NotFoundMenuPageComponent } from "./components/pages/not-found-menu-page/not-found-menu-page.component";
import { privilegeGuard } from "./guard/privilege-guard";


import { GroupManagementComponent } from "./components/pages/group-management/group-management.component";
import { GroupViewComponent } from "./components/pages/group-management/group-view/group-view.component";
import { PrioritizationComponent } from "./components/pages/prioritization/prioritization.component";
import { ScoreManagementComponent } from "./components/pages/score-management/score-management.component";
import { IISManagementComponent } from "./components/pages/iis-management/iis-management.component";
import { SubjectRegistrationComponent } from "./components/pages/subject-registration/subject-registration.component";
import { SubjectRegistrationViewComponent } from "./components/pages/subject-registration/subject-registration-view/subject-registration-view.component";

const routes: Routes = [
    {
        path: '', component: MainPageComponent, canActivate: [authGuard], canActivateChild: [privilegeGuard],
        children: [
            // { path: 'setting-module-info', component: ModuleSettingComponent },
            // { path: 'setting-role-info', component: RoleSettingComponent },
            // { path: 'setting-role-info/:roleId', component: RoleSettingComponent },
            // { path: 'province-setting', component: ProvinceSettingComponent },
            // { path: 'setting-org-info', component: OrgSettingComponent },
            // { path: 'setting-app-org', component: AppOrgSettingComponent },
            // { path: 'setting-app-info', component: AppSettingComponent },

            { path: 'setting-app-group', component: GroupManagementComponent },
            { path: 'setting-app-group-view', component: GroupViewComponent },
            { path: 'setting-prioritization', component: PrioritizationComponent },
            { path: 'setting-app-score', component: ScoreManagementComponent },
            { path: 'setting-app-iis', component: IISManagementComponent },
            { path: 'setting-subject-registration', component: SubjectRegistrationComponent },
            { path: 'setting-subject-registration-view', component: SubjectRegistrationViewComponent },
        ]
    },
    { path: 'un-authentication', component: UnAuthenticationComponent },
    { path: '404-menu', component: NotFoundMenuPageComponent },
    { path: 'login', component: LoginComponent, canActivate: [loginGuard] }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
