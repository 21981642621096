<div class="container-fluid">
    <div class="card">
        <dx-multi-view [swipeEnabled]="false" [selectedIndex]="0">
            <dxi-item>
                <div class="panel panel-default">
                    <div class="panel panel-default ">
                        <div class="panel-heading">
                            <div class="row justify-content-md-center">
                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                    <dx-select-box #select [dataSource]="appinfo" displayExpr="APP_NAME"
                                        valueExpr="APP_ID" placeholder="Module" [(value)]="formData.APP_ID"
                                        (onValueChanged)="OnAppSelected($event)">
                                    </dx-select-box>
                                </div>
                            </div>
                            <div style="text-align: right;padding-top: 10px;">
                                <div>
                                    <dx-button icon="add" class="btn-add-datagrid" type="success" text=" เพิ่ม " requirePermission="ADD"
                                        (onClick)="Add()">
                                    </dx-button>
                                </div>
                            </div>
                        </div>
                        <br>
                        <dx-data-grid #grid [dataSource]="admRole" [showColumnLines]="true" noDataText="ไม่มีข้อมูล"
                            [showRowLines]="true" [showBorders]="true" [columnHidingEnabled]="true"
                            [rowAlternationEnabled]="true" [hoverStateEnabled]="true" [selectedRowKeys]="[]"
                            noDataText="ไม่มีข้อมูล">
                            <dxo-search-panel [visible]="true" [width]="240" placeholder="ค้นหา..."></dxo-search-panel>
                            <dxo-paging [pageSize]="10"></dxo-paging>
                            <dxo-editing mode="cell" [allowUpdating]="false" [allowDeleting]="false"
                                [allowAdding]="false">
                            </dxo-editing>
                            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50]"
                                [showInfo]="true">
                            </dxo-pager>
                            <dxo-selection mode="single"></dxo-selection>
                            <dxi-column dataField="" caption="ลำดับที่" [width]="80" alignment="center"
                                [allowEditing]="false" cellTemplate="cellTemplate"></dxi-column>
                            <dxi-column dataField="" caption="แก้ไข" [width]="80" alignment="center"
                                [allowSorting]="false" cellTemplate="cellTemplateCommand"></dxi-column>
                            <div *dxTemplate="let d of 'cellTemplateCommand'">
                                <dx-button icon="edit" hint="แก้ไข" type="default" requirePermission="EDIT"
                                    (onClick)="Edit(d)">
                                </dx-button>
                            </div>


                            <dxi-column dataField="" caption="ลบ" [width]="100" alignment="center" [visible]="readonly"
                                [allowSorting]="false" cellTemplate="cellTemplateCommands"></dxi-column>
                            <div *dxTemplate="let d of 'cellTemplateCommands'">
                                <dx-button-improve icon="trash" hint="ลบ" type="danger" requirePermission="DELETE"
                                    (onClick)="Delete($event,d)">
                                </dx-button-improve>
                            </div>

                            <dxi-column dataField="ROLE_SEQ" caption="ลำดับสิทธิ์" alignment="center" [width]="150">
                            </dxi-column>
                            <dxi-column dataField="ROLE_CODE" caption="รหัส" alignment="center" [width]="150">
                            </dxi-column>

                            <dxi-column dataField="ROLE_NAME" caption="ชื่อสิทธิ์" alignment="center">
                            </dxi-column>
                            <dxi-column dataField="ORG_LV" caption="ระดับหน่วยงาน" alignment="center" [width]="130">
                            </dxi-column>
                            <dxi-column dataField="RECORD_STATUS" caption="สถานะการใช้งาน" [width]="130"
                                alignment="center" [calculateDisplayValue]="CalStatus">
                            </dxi-column>
                            <dxi-column dataField="CREATE_DATE" caption="วันที่สร้าง" [width]="130" dataType="date"
                            alignment="center" format="dateThai">
                            </dxi-column>
                            <!-- ในกรณีใช้ function ในการแปลง date เป็นภาษาไทย -->
                            <dxi-column dataField="CREATE_DATE" caption="วันที่สร้างแบบใช้ Function" [width]="130" dataType="date"
                            alignment="center" [calculateDisplayValue]="DisplayDate">
                            </dxi-column>
                            <div *dxTemplate="let cell of 'cellTemplate'">
                                {{ cell.component.pageIndex() * cell.component.pageSize() +
                                cell.rowIndex + 1 }}
                            </div>
                        </dx-data-grid>
                    </div>
                </div>
            </dxi-item>
            <dxi-item>
                <ng-container *dxTemplate>
                    <app-role-edit [mainForm]="this"></app-role-edit>
                </ng-container>
            </dxi-item>
        </dx-multi-view>
    </div>
</div>
