import { Component, ViewChild, type OnInit } from '@angular/core';
import { DxMultiViewComponent } from 'devextreme-angular';
import { Router,NavigationExtras } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { PrioritizationEditComponent } from './prioritization-edit/prioritization-edit.component';
import { PrioritizationService, IPrioritizationInfo } from 'src/app/services/prioritization.service';
import { GroupService, IGroupInfo, GroupFilterParam } from 'src/app/services/group.service';
import { User } from 'src/app/services/user';
import { showConfirmDialog, showDialog } from 'src/app/common/dialog/dialog';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-prioritization',
    templateUrl: './prioritization.component.html',
    styleUrls: ['./prioritization.component.scss'],
})
export class PrioritizationComponent implements OnInit {

    @ViewChild(PrioritizationEditComponent, { static: true }) form: PrioritizationEditComponent;
    @ViewChild(DxMultiViewComponent, { static: true }) multiView: DxMultiViewComponent;

    dataSource: DataSource; //DataSource;
    dataSourceGroup: DataSource; //DataSource;
    dataCriteria= null
    dataSourceGroupSelect= null

    prioritization: IPrioritizationInfo[];
    _filter: GroupFilterParam = {};
    _isLoading = false;
    _index = 0;
    groupData = [];
    prioritizationData = [];
    CRITERIA_QTY = "";
    formGroup: IGroupInfo;
    CREATE = false;

    public formPrioritization: IPrioritizationInfo;

    constructor(
        private servicePrioritization: PrioritizationService,
        private serviceGroup: GroupService,
        private route: ActivatedRoute,
        private router: Router,) {
        this.formPrioritization = {} as any;
    }

    ngOnInit() {
        this.form.mainForm = this;
        this.formGroup = {}
        this.CRITERIA_QTY = ""
        this.Load();
    }

    async Load() {
        this._isLoading = true;

        this.prioritizationData = await this.serviceGroup
            .GetGroup({
                requireTotalCount: true,
                offset: 0,
                length: 20,
                patternName: this._filter.patternName,
                recordStatus: false,
            })
            .toPromise()
            .then((data: any) => data.Data)
            .catch(error => {
                console.log(error)
                this._isLoading = false;
                throw new Error('Data Loading Error');
            });

        this.route.queryParamMap.subscribe(async params => {
            const patternId = params.get('PATTERN_ID');
            if (patternId) {
                this.formGroup.PATTERN_ID = Number(patternId)
                const dataArray = this.prioritizationData
                const index = dataArray.findIndex(e=>e.PATTERN_ID === Number(patternId));
                const dataPattern = dataArray[index]
                this.CRITERIA_QTY = dataPattern.CRITERIA_QTY.toString();
                this.dataSourceGroupSelect = dataPattern;
                this.dataCriteria = await this.servicePrioritization
                    .GetPrioritization({
                        requireTotalCount: true,
                        offset: 0,
                        length: 20,
                        patternid: dataPattern.PATTERN_ID,
                        recordStatus: false
                    })
                    .toPromise()
                    .then((data: any) => ({
                        data: data.Data,
                        totalCount: data.TotalCount,
                    }))
                    .catch(error => {
                        console.log(error)
                        this._isLoading = false;
                        throw new Error('Data Loading Error');
                    });

                this.prioritization = this.dataCriteria.data
            }
        })

        // console.log('prioritizationData : ',this.prioritizationData)
        this._isLoading = false;
    }

    search() {
        this.dataSource.reload();
    }

    async OnGroupSelected(event) {
        const dataArray = this.prioritizationData
        const index = dataArray.findIndex(e=>e.PATTERN_ID === event.value);
        const dataPattern = dataArray[index]
        this.CRITERIA_QTY = dataPattern.CRITERIA_QTY.toString();
        console.log('dataPattern : ',dataPattern)
        this.dataSourceGroupSelect = dataPattern;
        this.dataCriteria = await this.servicePrioritization
            .GetPrioritization({
                requireTotalCount: true,
                offset: 0,
                length: 20,
                patternid: dataPattern.PATTERN_ID,
                recordStatus: false
            })
            .toPromise()
            .then((data: any) => ({
                data: data.Data,
                totalCount: data.TotalCount,
            }))
            .catch(error => {
                console.log(error)
                this._isLoading = false;
                throw new Error('Data Loading Error');
            });

        this.prioritization = this.dataCriteria.data

        console.log('prioritization : ',this.prioritization)
    }

    GenRisk(id) {
        console.log("ID : ",id)
        console.log("CRITERIA_QTY : ",this.CRITERIA_QTY)
        this.CREATE = true;
        const dataArray = this.prioritizationData;
        const data = dataArray.filter(e=>e.PATTERN_ID === id);
        const array = [];
        for(let i = 0 ; i < data[0].CRITERIA_QTY ; i++){
            array.push({
                PatternCriteriaKeyId: {
                    PATTERN_ID: data[0].PATTERN_ID,
                    CRITERIA_ID: i+1,
                    PATTERN_NAME: data[0].PATTERN_NAME, // ชื่อรูปการประเมินคัดเลือก
                },
                CRITERIA_NAME: "", // หัวข้อ
                CRITERIA_DESC: "", // คำอธิบาย
                WEIGHT: 0, // คะแนนถ่วง นน
                CHOICE_QTY: 0, // จำนวนข้อ (ระดับความเสี่ยง)
            })
        }
        this.prioritization = array;
        console.log("this.prioritization:",this.prioritization)


    }
    Edit(e) {
        this.formPrioritization = Object.assign({}, e.data);
        this.multiView.selectedIndex = 1;
        this.form.onEdit(e.data,this.dataSourceGroupSelect.PATTERN_NAME)
    }
    Edit_Score(e) {
        console.log(this.dataSourceGroupSelect)
        const navigationExtras: NavigationExtras = {
            state: {
                data: e.data
            },
            queryParams: {
                PATTERN_ID:e.data.PatternCriteriaKeyId.PATTERN_ID,
                PATTERN_NAME:this.dataSourceGroupSelect.PATTERN_NAME,
                CRITERIA_ID:e.data.PatternCriteriaKeyId.CRITERIA_ID,
            }
          };
        this.router.navigate(["/setting-app-score"],navigationExtras);
    }

    View(e) {
        this.formPrioritization = Object.assign({}, e.data);
        this.multiView.selectedIndex = 1;
    }

    Save(item) {
        if(this.CREATE){
            this.prioritization = this.prioritization.map(e=> {return {...e,PatternCriteriaKeyId:{...e.PatternCriteriaKeyId,CRITERIA_ID:0}}})
        }
        console.log("item:",item);
        const dataArray = this.prioritizationData;
        const index = dataArray.findIndex(e=>e.PATTERN_ID === item.PATTERN_ID);
        this.prioritization[index] = item;
        console.log("this.prioritization:",this.prioritization);

        item.startWait();
        this.servicePrioritization.PostPrioritization(this.prioritization)
            .pipe(finalize(() => item.stopWait()))
            .subscribe(_ => {
                this.multiView.selectedIndex = 0;
                showDialog({
                    type: "info",
                    title: "สำเร็จ!",
                    message: "บันทึกเรียบร้อย",
                })
                this.dataSource.reload();
                this.clearForm();
            });

    }

    public clearForm() {

    }

    cancel() {
        window.location.reload()
    }

    Back() {
        this.clearForm();
        this.multiView.selectedIndex = 0;
    }
}
