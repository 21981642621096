import { Component, ViewChild, type OnInit } from '@angular/core';
import { DxMultiViewComponent } from 'devextreme-angular';
import { Router,ActivatedRoute } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { PrioritizationService, IPrioritizationInfo } from 'src/app/services/prioritization.service';
import { GroupService, IGroupInfo, GroupFilterParam } from 'src/app/services/group.service';
import { User } from 'src/app/services/user';
import { showConfirmDialog, showDialog } from 'src/app/common/dialog/dialog';
import { finalize } from 'rxjs/operators';
import { ChoiceService } from 'src/app/services/choice.service';

@Component({
    selector: 'app-prioritization',
    templateUrl: './group-view.component.html',
    styleUrls: ['./group-view.component.scss'],
})
export class GroupViewComponent implements OnInit {

    @ViewChild(DxMultiViewComponent, { static: true }) multiView: DxMultiViewComponent;

    prioritization: IPrioritizationInfo[];
    _filter: GroupFilterParam = {};
    _isLoading = false;
    _index = 0;
    groupData = [];
    CRITERIA_QTY = "";
    PATTERN_ID = 0;
    PATTERN_NAME = "";
    formGroup: IGroupInfo;
    cellData = "";
    OPINION_FLAG = "";
    RATING_FLAG = "";
    WEIGHT_FLAG = "";

    public formPrioritization: IGroupInfo;

    constructor(
        private servicePrioritization: PrioritizationService,
        private serviceChoice: ChoiceService,
        private serviceGroup: GroupService,
        private router: Router,
        private route: ActivatedRoute) {
        this.formPrioritization = {} as any;
    }

    ngOnInit() {
        // this.form.mainForm = this;
        this.route.queryParamMap.subscribe(async params => {
            const patternId = params.get('PATTERN_ID');
            const data = await this.servicePrioritization
            .GetPrioritization({
                requireTotalCount: true,
                offset: 0,
                length: 20,
                patternid: Number(patternId),
                recordStatus: false
            })
            .toPromise()
            .then((data: any) => ({
                data: data.Data,
                totalCount: data.TotalCount,
            }))
            .catch(error => {
                console.log(error)
                this._isLoading = false;
                throw new Error('Data Loading Error');
            });


            this.PATTERN_ID = Number(patternId);
            this.PATTERN_NAME = params.get('PATTERN_NAME');
            this.OPINION_FLAG = params.get('OPINION_FLAG');
            this.RATING_FLAG = params.get('RATING_FLAG');
            this.WEIGHT_FLAG = params.get('WEIGHT_FLAG');

            const groupChioce = await this.serviceChoice.GetChoicePattern(this.PATTERN_ID)
                .toPromise()
                .then((data: any) => ({
                    data: data
                }))

                .catch(error => {
                    console.log(error)
                    this._isLoading = false;
                    throw new Error('Data Loading Error');
                });

            for(const item of data.data) {
                const array = groupChioce.data.filter(e => e.PatternCriteriaChoiceKeyId.CRITERIA_ID == item.PatternCriteriaKeyId.CRITERIA_ID && e.RECORD_STATUS == 'A')
                item.choice = array
            }

            this.groupData = data.data;

            console.log('groupData : ',this.groupData)

        });
    }
    onTextareaChange(e){
console.log(e)
    }
    onRadioButtonChange(e){
console.log(e)
    }

}
