import { IPostcodeInfo, PostcodeService } from '../../../../services/postcode.service';
import { CommonModule } from '@angular/common';
import { Component, ViewChild, type OnInit, Input } from '@angular/core';
import { User } from 'src/app/services/user';
import DataSource from 'devextreme/data/data_source';
import { DxFormComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { PrioritizationService, IPrioritizationInfo } from 'src/app/services/prioritization.service';
import { finalize } from 'rxjs/operators';
import { showDialog } from 'src/app/common/dialog/dialog';
import { PrioritizationComponent } from '../prioritization.component';

@Component({
    selector: 'app-prioritization-edit',
    templateUrl: './prioritization-edit.component.html',
    styleUrls: ['./prioritization-edit.component.scss'],
})
export class PrioritizationEditComponent implements OnInit {
    @ViewChild(DxFormComponent, { static: true }) form: DxFormComponent;
    @ViewChild('select') select: DxSelectBoxComponent;
    @Input() formPrioritization: IPrioritizationInfo;
    public mainForm: PrioritizationComponent;

    name = "";
    PATTERN_NAME ="";

    constructor(
        private servicePrioritization: PrioritizationService) {
        // this.formPrioritization = {} as any;
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnInit() {}

    onEdit(data,PATTERN_NAME){
        this.PATTERN_NAME = PATTERN_NAME;
        this.formPrioritization = data;
        // console.log("mainForm:",this.mainForm)
    }

    SaveCriteria(e) {
        // console.log(this.formPrioritization)
        if (!this.form.instance.validate().isValid) {
            showDialog({
                type: "error",
                title: "เกิดข้อผิดพลาด!",
                message: "กรุณากรอกข้อมูลให้ครบถ้วน",
            });
            return;
        }

        e.startWait();
        if (this.formPrioritization.PatternCriteriaKeyId.CRITERIA_ID && this.formPrioritization.PatternCriteriaKeyId.CRITERIA_ID != 0) {
            this.servicePrioritization.PutPrioritization(this.formPrioritization.PatternCriteriaKeyId.CRITERIA_ID, {
                PatternCriteriaKeyId: {
                    PATTERN_ID : this.formPrioritization.PatternCriteriaKeyId.PATTERN_ID,
                    CRITERIA_ID : this.formPrioritization.PatternCriteriaKeyId.CRITERIA_ID,
                },
                CRITERIA_NAME : this.formPrioritization.CRITERIA_NAME,
                CRITERIA_DESC : this.formPrioritization.CRITERIA_DESC,
                WEIGHT : this.formPrioritization.WEIGHT,
                CHOICE_QTY : this.formPrioritization.CHOICE_QTY,
            })
            .pipe(finalize(() => e.stopWait()))
            .subscribe(_ => {
                this.mainForm.multiView.selectedIndex = 0;
                this.mainForm.dataSource.reload();
                showDialog({
                    type: "info",
                    title: "สำเร็จ!",
                    message: "บันทึกเรียบร้อย",
                })
                this.clearForm();
            });
        } else {
            this.servicePrioritization.PostPrioritization({
                PatternCriteriaKeyId: {
                    PATTERN_ID : this.formPrioritization.PatternCriteriaKeyId.PATTERN_ID,
                    CRITERIA_ID : 0,
                },
                CRITERIA_NAME : this.formPrioritization.CRITERIA_NAME,
                CRITERIA_DESC : this.formPrioritization.CRITERIA_DESC,
                WEIGHT : this.formPrioritization.WEIGHT,
                CHOICE_QTY : this.formPrioritization.CHOICE_QTY,
            })
            .pipe(finalize(() => e.stopWait()))
            .subscribe(_ => {
                this.mainForm.multiView.selectedIndex = 0;
                showDialog({
                    type: "info",
                    title: "สำเร็จ!",
                    message: "บันทึกเรียบร้อย",
                })
                this.mainForm.dataSource.reload();
                this.clearForm();
            });
        }
    }

    public clearForm() {
        this.form.instance.resetValues();
    }
    Save() {
        // this.clearForm();
        const index = this.mainForm.prioritization.findIndex(e=>e.PatternCriteriaKeyId.CRITERIA_ID === this.formPrioritization.PatternCriteriaKeyId.CRITERIA_ID);
        this.mainForm.prioritization[index] = this.formPrioritization;

        this.mainForm.multiView.selectedIndex = 0;
    }

    Back() {
        // this.clearForm();
        this.mainForm.multiView.selectedIndex = 0;
    }
}
