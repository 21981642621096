import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { req } from '../common/betimes-http-request';
import { trimObject } from '../common/helper';
import { IPagingResult, OffsetFilterParam } from '../common/common-type';
import DataSource from 'devextreme/data/data_source';

@Injectable({
    providedIn: 'root'
})
export class SubjectService {

    [x: string]: any;

    constructor() { }

    public GetSubject(filter?: SubjectFilterParam): Observable<IPagingResult<ISubjectInfo>> {
        trimObject(filter);
        return req<IPagingResult<ISubjectInfo>>('/mossubject')
            .queryString( filter as any)
            .get();
    }

    public GetSubjectByID(id: number): Observable<ISubjectInfo> {
        return req<ISubjectInfo>('/mossubject/' + id).get();
    }

    public PostSubject(param: ISubjectInfo): Observable<ISubjectInfo> {
        return req<ISubjectInfo>('/mossubject').body(param).post();
    }

    public PutSubject(id: number, param: ISubjectInfo): Observable<ISubjectInfo> {
        return req<ISubjectInfo>('/mossubject/' + id).body(param).put();
    }
}

export interface ISubjectInfo {
    SUBJECT_ID?: number;
    BUDGET_YEAR?: string;
    AUDIT_ORGANIZE_ID?: number;
    APP_INFO_ID?: number;
    SUBJECT_INFO?: string;
    SUBJECT_DESC?: string;
    MAIN_ORGANIZE_ID?: number;
    PLNY_TEAM_ID?: number;
    PLNY_LAYER_CODE?: number;
    PATTERN_ID?: number;
    RECORD_STATUS?: string;
    DEL_FLAG?: string;

}


export interface SubjectFilterParam extends OffsetFilterParam {
    budgetyear?: string;
}
